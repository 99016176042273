"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Note = void 0;
const THREE = __importStar(require("three"));
const baseObject_1 = require("../../utils/baseObject");
const threeScene_1 = require("../../utils/three/threeScene");
const gameface_1 = require("../gameface/gameface");
const gameScene_1 = require("../scenes/gameScene/gameScene");
const mainScene_1 = require("../scenes/mainScene");
const input_1 = require("../../utils/input/input");
const button_1 = require("../../utils/ui/button");
const editorScene_1 = require("../scenes/editor/editorScene");
const packet_1 = require("../network/packet");
class Note extends baseObject_1.BaseObject {
    get songNote() { return this.noteData.songNote; }
    ;
    constructor(noteData) {
        super();
        //public visible: boolean = true;
        //public noteVisible: boolean = true;
        this.destroyed = false;
        //public canMove: boolean = true;
        this.padIndex = -1;
        this.dragTotalSize = 5;
        this.dragSize = this.dragTotalSize;
        this.noteData = noteData;
    }
    update() {
        let inGameField = this.noteData.isInGameField();
        if (inGameField) {
            this.updateSprites();
            this.updateDragObject();
        }
        else {
            if (this.container) {
                this.destroy();
            }
        }
        if (this.noteData.hasPassedEndGameField()) {
            if (!this.noteData.hitted) {
                if (!this.noteData.missed) {
                    this.noteData.missed = true;
                    this.log("missed note; breaking combo");
                    gameface_1.Gameface.Instance.gameLogic.breakCombo();
                    gameface_1.Gameface.Instance.network.send(packet_1.PACKET_TYPE.PACKET_NOTE_MISS, {});
                }
            }
        }
    }
    updateSprites() {
        this.destroyed = false;
        const scene = gameScene_1.GameScene.Instance;
        const threeScene = threeScene_1.ThreeScene.Instance;
        if (!this.object) {
            const box = threeScene.third.add.box({ width: 0.1, height: 0.1, depth: 0.1 });
            const object = threeScene_1.ThreeScene.addPhaser3DObject(box);
            this.object = object;
            object.name = "Note";
            //object.debugText.createDebugText();
            this.updatePositionRelativeToPad();
            //box.position.set(0, 0, padPosition.z);
        }
        if (!this.container) {
            this.container = scene.add.container(0, 0);
            mainScene_1.MainScene.Instance.layerNotes.add(this.container);
        }
        if (this.container) {
            //this.container.visible = Math.random() * 100 > 10;
            let createSprite = true;
            if (this.noteData.hitted)
                createSprite = false;
            if (createSprite) {
                if (!this.sprite) {
                    this.sprite = scene.add.sprite(0, 0, "note_sheet", "note1.png");
                    this.container.add(this.sprite);
                }
                if (!this.spriteColor) {
                    this.spriteColor = scene.add.sprite(0, 0, "note_sheet", "note_color1.png");
                    this.spriteColor.anims.play('note_color_idle');
                    this.container.add(this.spriteColor);
                }
                const pad = this.getPad();
                if (pad)
                    this.spriteColor.setTint(pad.color);
            }
            else {
                if (this.sprite) {
                    this.sprite.destroy();
                    this.sprite = undefined;
                }
                if (this.spriteColor) {
                    this.spriteColor.destroy();
                    this.spriteColor = undefined;
                }
            }
        }
    }
    getPad() {
        return gameScene_1.GameScene.Instance.pads.getPad(this.padIndex);
        ;
    }
    updateDragObject() {
        var _a;
        if (this.dragSize > 0) {
            if (!this.dragObject) {
                this.dragObject = this.createDragPart(this.dragSize);
            }
        }
        const dragObject = (_a = this.dragObject) === null || _a === void 0 ? void 0 : _a.object;
        if (dragObject && this.object) {
            const object = this.object.object;
            const newPosition = object.position.clone();
            if (this.draggedByPad) {
                const pad = this.draggedByPad;
                const padPosition = pad.object.object.position;
                const currentTime = gameScene_1.GameScene.Instance.soundPlayer.getCurrentSoundPosition();
                const dragTime = this.songNote.dragTime;
                const noteTime = this.songNote.time;
                const originalSize = gameScene_1.GameScene.Instance.notes.getDistanceFromMs(dragTime);
                const startedDragAt = pad.startedDragAtTime * 1000;
                const diff = noteTime - startedDragAt;
                const pressedDiff = currentTime - startedDragAt;
                //console.log("originalSize", originalSize);
                //console.log("pressedDiff", pressedDiff);
                //console.log("diff", diff);
                this.dragSize = originalSize + gameScene_1.GameScene.Instance.notes.getDistanceFromMs(diff);
                this.dragSize -= gameScene_1.GameScene.Instance.notes.getDistanceFromMs(pressedDiff);
                newPosition.set(padPosition.x, padPosition.y, padPosition.z);
            }
            else {
            }
            newPosition.z -= this.dragSize / 2;
            dragObject.scale.set(1, this.dragSize, 1);
            dragObject.position.set(newPosition.x, newPosition.y, newPosition.z);
            this.dragObject.debugText.setLine("size", this.dragSize.toFixed(2));
        }
        this.updateContainerPosition();
        if (this.container && this.object) {
            //+ button
            const distanceFromMouse = this.getDistanceFromMouse();
            if (distanceFromMouse < 30 && editorScene_1.EditorScene.showNoteOptionsButton && editorScene_1.EditorScene.isRunning()) {
                if (!this.moreOptionsNoteButton) {
                    this.moreOptionsNoteButton = new button_1.Button(mainScene_1.MainScene.Instance, "+", 0, 0, 30, 30, "button");
                    this.moreOptionsNoteButton.onClick = () => {
                        editorScene_1.EditorScene.Instance.openNoteOptions(this.songNote.time);
                    };
                    this.container.add(this.moreOptionsNoteButton.container);
                }
            }
            else {
                if (this.moreOptionsNoteButton) {
                    this.moreOptionsNoteButton.destroy();
                    this.moreOptionsNoteButton = undefined;
                }
            }
        }
    }
    updateContainerPosition() {
        if (this.container && this.object) {
            const object = this.object.object;
            //position
            const screenPosition = threeScene_1.ThreeScene.projectToScreen(object.position);
            this.container.setPosition(screenPosition.x, screenPosition.y);
            //scale
            const scale = this.getScale();
            this.container.setScale(scale);
        }
    }
    isBeeingDragged() {
        return this.draggedByPad != undefined;
    }
    getScale() {
        if (!this.object)
            throw "Object is not defined";
        return this.object.getScale() * 0.75;
    }
    getDistanceFromMouse() {
        if (!this.container)
            return 0;
        const mousePosition = input_1.Input.mousePosition;
        return mousePosition.distance(new Phaser.Math.Vector2(this.container.x, this.container.y));
    }
    destroy() {
        var _a, _b, _c, _d, _e, _f;
        this.destroyed = true;
        (_a = this.object) === null || _a === void 0 ? void 0 : _a.destroy();
        this.object = undefined;
        (_b = this.dragObject) === null || _b === void 0 ? void 0 : _b.destroy();
        this.dragObject = undefined;
        (_c = this.sprite) === null || _c === void 0 ? void 0 : _c.destroy();
        this.sprite = undefined;
        (_d = this.spriteColor) === null || _d === void 0 ? void 0 : _d.destroy();
        this.spriteColor = undefined;
        (_e = this.container) === null || _e === void 0 ? void 0 : _e.destroy();
        this.container = undefined;
        (_f = this.moreOptionsNoteButton) === null || _f === void 0 ? void 0 : _f.destroy();
        this.moreOptionsNoteButton = undefined;
    }
    getDistanceFromPad(pad) {
        if (!this.object)
            throw "Object is not defined";
        const padPosition = pad.object.object.position;
        const position = this.object.object.position;
        const distance = padPosition.distanceTo(position);
        return distance;
    }
    createDragPart(size) {
        const threeScene = threeScene_1.ThreeScene.Instance;
        const shapeSize = new THREE.Vector2(0.1, 1);
        //ground
        //const texture = new THREE.TextureLoader().load('assets/drag.png');
        const pad = this.getPad();
        const material = new THREE.MeshStandardMaterial({ color: pad.color });
        // Cria o cubo com a geometria personalizada e o material
        const obj = threeScene.third.add.plane({
            width: shapeSize.x,
            height: shapeSize.y,
            x: 0,
            y: 0,
            z: 0
        }, { custom: material });
        const object = threeScene_1.ThreeScene.addPhaser3DObject(obj);
        object.name = "Drag";
        obj.rotation.x = Math.PI / 2;
        obj.position.set(0, 2, 0);
        return object;
    }
    setDragSize(size) {
        this.dragSize = size;
    }
    set3DPosition(x, y, z) {
        if (!this.object)
            throw "Object not created yet";
        this.object.object.position.x = x;
        this.object.object.position.y = y;
        this.object.object.position.z = z;
    }
    startBeeingDragged(pad) {
        this.draggedByPad = pad;
    }
    stopBeeingDragged() {
        this.draggedByPad = undefined;
        this.dragSize = 0;
        //this.visible = false;
    }
    updatePositionRelativeToPad() {
        const pad = this.getPad();
        let z = pad.object.object.position.z;
        let ms = (gameScene_1.GameScene.Instance.soundPlayer.getCurrentSoundPosition()) - this.songNote.time;
        z += gameScene_1.GameScene.Instance.notes.getDistanceFromMs(ms);
        this.set3DPosition(pad.object.object.position.x, pad.object.object.position.y, z);
    }
}
exports.Note = Note;
