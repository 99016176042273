"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BPMBar = void 0;
const threeScene_1 = require("../../../utils/three/threeScene");
const gameScene_1 = require("../gameScene/gameScene");
const mainScene_1 = require("../mainScene");
class BPMBar {
    constructor(scene) {
        this.scale = 1.5;
        this.isSubDivision = false;
        this.timeMs = 0;
        const threeScene = threeScene_1.ThreeScene.Instance;
        const obj = threeScene.third.add.box({
            width: 0.2,
            height: 0.2,
            depth: 0.2,
            x: 0,
            y: 0,
            z: 0
        }, {});
        this.object = threeScene_1.ThreeScene.addPhaser3DObject(obj);
        //this.object.debugText.createDebugText();
        this.object.setInvisible();
        this.image = scene.add.image(0, 0, "bpm_divisor");
        mainScene_1.MainScene.Instance.layerGround.add(this.image);
    }
    update() {
        //object
        const time = gameScene_1.GameScene.Instance.soundPlayer.getCurrentSoundPosition();
        let z = gameScene_1.GameScene.Instance.ground.plankSize / 2;
        const distance = gameScene_1.GameScene.Instance.notes.getDistanceFromMs(time - this.timeMs);
        z += distance;
        let y = gameScene_1.GameScene.Instance.pads.padHeight;
        this.object.object.position.set(0, y, z);
        //image
        const screenPos = threeScene_1.ThreeScene.projectToScreen(this.object.object.position);
        this.image.setPosition(screenPos.x, screenPos.y);
        this.image.setScale(this.object.getScale() * this.scale);
    }
    destroy() {
        this.image.destroy();
        this.object.destroy();
    }
}
exports.BPMBar = BPMBar;
