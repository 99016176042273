"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NumberRange = void 0;
const button_1 = require("./button");
class NumberRange {
    constructor(scene, displaySize) {
        this._displaySize = 150;
        this.value = 2;
        this.addBy = 0.1;
        this._displaySize = displaySize;
        this.container = scene.add.container(0, 0);
        const background = scene.add.rectangle(0, 0, displaySize, 30, 0xffffff);
        this.container.add(background);
        const leftButton = new button_1.Button(scene, "<", -this._displaySize / 2, 0, 30, 30, "button");
        this.leftButton = leftButton;
        leftButton.onClick = () => {
        };
        this.container.add(leftButton.container);
        const rightButton = new button_1.Button(scene, ">", this._displaySize / 2, 0, 30, 30, "button");
        this.rightButton = rightButton;
        rightButton.onClick = () => {
        };
        this.container.add(rightButton.container);
        this.text = scene.add.text(0, 0, "OPTIONS_DISPLAY", { font: '16px Arial', color: '#000000' });
        this.text.setOrigin(0.5);
        this.container.add(this.text);
    }
    update() {
        var _a;
        const prevValue = this.value;
        if (this.leftButton.isPointerDown) {
            this.value -= this.addBy;
        }
        if (this.rightButton.isPointerDown) {
            this.value += this.addBy;
        }
        if (this.value != prevValue) {
            (_a = this.onValueChange) === null || _a === void 0 ? void 0 : _a.call(this);
        }
        this.text.setText(`${this.value.toFixed(2)}`);
    }
}
exports.NumberRange = NumberRange;
