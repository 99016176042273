"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Network = void 0;
const socket_io_client_1 = require("socket.io-client");
const baseObject_1 = require("../../utils/baseObject");
const packetListener_1 = require("./packetListener");
class Network extends baseObject_1.BaseObject {
    get socket() { return this._socket; }
    constructor() {
        super();
        //private _lastSentData = performance.now();
        this._packetListener = new packetListener_1.PacketListener();
        this.initSocket();
    }
    initSocket() {
        this._socket = (0, socket_io_client_1.io)(this.getAddress(), {
            autoConnect: false,
            reconnection: false
        });
        this._socket.once('connect', () => {
            var _a;
            console.log("connect");
            (_a = this._onConnectCallback) === null || _a === void 0 ? void 0 : _a.call(this);
        });
        this._socket.on('p', (packet) => {
            this.onReceivePacket(packet);
        });
        this._socket.on('error', () => {
            console.log("error");
        });
    }
    send(packetType, data) {
        const packet = {
            type: packetType,
            data: data
        };
        this._socket.emit('p', packet);
        this.log(`sent packet '${packet.type}'`);
    }
    onReceivePacket(packet) {
        this.log(`reiceved packet ${packet.type}`);
        this._packetListener.emitReceivedPacketEvent(packet);
    }
    connectAndWait() {
        return new Promise((resolve => {
            this.connect(() => {
                resolve();
            });
        }));
    }
    connect(callback) {
        var _a;
        this.log("connect");
        this.log(`address: (${this.getAddress()})`);
        this._onConnectCallback = callback;
        if (this.socket.connected) {
            (_a = this._onConnectCallback) === null || _a === void 0 ? void 0 : _a.call(this);
            return;
        }
        this._socket.connect();
    }
    getAddress() {
        return `${location.protocol}//${location.host}`;
    }
    update(delta) {
    }
    waitForPacket(type) {
        return new Promise((resolve) => {
            this._packetListener.listen(type, (data) => {
                resolve(data);
            });
        });
    }
}
exports.Network = Network;
Network.REDIRECT_URL = "";
