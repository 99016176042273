"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Options = void 0;
const button_1 = require("./button");
class Options {
    constructor(scene, displaySize) {
        this._optionDisplaySize = 150;
        this._options = [];
        this._currentOptionIndex = 0;
        this._optionDisplaySize = displaySize;
        this.container = scene.add.container(0, 0);
        this.container.setPosition(200, 200);
        const background = scene.add.rectangle(0, 0, displaySize, 30, 0xffffff);
        this.container.add(background);
        const leftButton = new button_1.Button(scene, "<", -this._optionDisplaySize / 2, 0, 30, 30, "button");
        leftButton.onClick = () => {
            this.addOptionBy(-1);
        };
        this.container.add(leftButton.container);
        const rightButton = new button_1.Button(scene, ">", this._optionDisplaySize / 2, 0, 30, 30, "button");
        rightButton.onClick = () => {
            this.addOptionBy(1);
        };
        this.container.add(rightButton.container);
        this.text = scene.add.text(0, 0, "OPTIONS_DISPLAY", { font: '16px Arial', color: '#000000' });
        this.text.setOrigin(0.5);
        this.container.add(this.text);
    }
    addOption(text, value) {
        const option = {
            text: text,
            value: value
        };
        this._options.push(option);
    }
    getCurrentOption() {
        return this._options[this._currentOptionIndex];
    }
    getCurrentOptionValue() {
        return this.getCurrentOption().value;
    }
    addOptionBy(by) {
        var _a;
        const prevValue = this._currentOptionIndex;
        this._currentOptionIndex += by;
        const max = this._options.length - 1;
        if (this._currentOptionIndex > max)
            this._currentOptionIndex = max;
        if (this._currentOptionIndex < 0)
            this._currentOptionIndex = 0;
        const newValue = this._currentOptionIndex;
        if (prevValue != newValue) {
            (_a = this.onOptionChange) === null || _a === void 0 ? void 0 : _a.call(this);
        }
    }
    update() {
        const option = this.getCurrentOption();
        this.text.setText(option.text);
    }
}
exports.Options = Options;
