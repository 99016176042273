"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PreloadScene = void 0;
class PreloadScene extends Phaser.Scene {
    constructor() {
        super({});
    }
    preload() {
        this.load.image("test", "/assets/test.png");
        this.load.image("background_load", "/assets/background/background_load.png");
        this.load.image("how_to_play", "/assets/background/how_to_play.png");
    }
    create() {
        return __awaiter(this, void 0, void 0, function* () {
            var _a;
            (_a = this.onPreloadFinish) === null || _a === void 0 ? void 0 : _a.call(this);
        });
    }
    update(time, delta) {
    }
}
exports.PreloadScene = PreloadScene;
