"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Notes = void 0;
const baseObject_1 = require("../../utils/baseObject");
const gameSettings_1 = require("../constants/gameSettings");
const gameScene_1 = require("../scenes/gameScene/gameScene");
const note_1 = require("./note");
class Notes extends baseObject_1.BaseObject {
    get notes() { return this._notes; }
    ;
    get soundPlayer() { return gameScene_1.GameScene.Instance.soundPlayer; }
    constructor() {
        super();
        this.delta = 0;
        this._notes = [];
    }
    spawnNote(noteData) {
        //const pad = GameScene.Instance.pads.getPad(padIndex);
        //const padPosition = pad.object.object.position;
        //const scene = ThreeScene.Instance;
        const note = new note_1.Note(noteData);
        note.padIndex = noteData.padIndex;
        this._notes.push(note);
        note.update();
        /*
        const position = pad.getPosition();

        const distance = this.getSpawnNoteDistance();
        */
        return note;
    }
    destroyNotes() {
        for (const note of this._notes) {
            note.destroy();
        }
        this._notes = [];
    }
    update(delta) {
        this.delta = delta;
        const notesToDestroy = [];
        for (const note of this._notes) {
            note.update();
            if (!note.noteData.isInGameField())
                continue;
            note.updatePositionRelativeToPad();
            note.updateContainerPosition(); //to fix position not syncing
        }
        for (const note of notesToDestroy) {
            this._notes.splice(this._notes.indexOf(note), 1);
            note.destroy();
        }
    }
    getNoteByNoteData(noteData) {
        for (const note of this.notes) {
            if (note.noteData == noteData)
                return note;
        }
        throw `Notes: Could not find note by note data`;
    }
    getSpawnNoteDistance() {
        const z = gameScene_1.GameScene.Instance.ground.plankSize;
        return z;
    }
    getMovementSpeed() {
        const delta = this.delta;
        const distanceToMove = this.getSpawnNoteDistance();
        const timeToAchieve = gameSettings_1.gameSettings.noteTimeToAchieve;
        const amount = distanceToMove / timeToAchieve * delta;
        return amount;
    }
    getDistanceFromMs(ms) {
        const distanceToMove = this.getSpawnNoteDistance();
        const timeToAchieve = gameSettings_1.gameSettings.noteTimeToAchieve;
        const mettersPerMs = distanceToMove / timeToAchieve;
        //3m / 1000ms
        //0.03 m/ms
        return mettersPerMs * ms;
    }
    isDistanceBetweenMsInterval(distance, ms) {
        const distanceFromMs = this.getDistanceFromMs(ms);
        const goodDistance = distanceFromMs / 2;
        //console.log(`check if ${distance.toFixed(2)} <= ${goodDistance.toFixed(2)}`)
        if (distance <= goodDistance)
            return true;
        return false;
    }
    getLastNote() {
        const notes = this._notes.sort((a, b) => a.songNote.time - b.songNote.time);
        const lastNote = notes[notes.length - 1];
        return lastNote;
    }
    getNotesHitted() {
        return this._notes.filter(note => note.noteData.hitted);
    }
    getNotesMissed() {
        return this._notes.filter(note => note.noteData.missed);
    }
}
exports.Notes = Notes;
