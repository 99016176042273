"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Timebar = void 0;
const baseObject_1 = require("../../../utils/baseObject");
const input_1 = require("../../../utils/input/input");
const utils_1 = require("../../../utils/utils");
class Timebar extends baseObject_1.BaseObject {
    constructor() {
        super();
        this.events = new Phaser.Events.EventEmitter();
        this.size = new Phaser.Math.Vector2(800, 40);
        this.currentLength = 120.0;
        this.totalLength = 1524.0;
    }
    create(scene) {
        const container = scene.add.container(0, 0);
        this.container = container;
        container.setPosition(10, 40);
        const background = scene.add.rectangle(0, 0, this.size.x, this.size.y, 0x646400);
        background.setOrigin(0);
        container.add(background);
        const indicator = scene.add.rectangle(0, 0, 2, this.size.y - 10, 0xffffff);
        indicator.setOrigin(0.5, 0);
        container.add(indicator);
        this.indicator = indicator;
        const timeText = scene.add.text(0, this.size.y / 2, "0:00:00");
        timeText.setOrigin(0, 0.5);
        container.add(timeText);
        this.timeText = timeText;
        input_1.Input.events.on("pointerdown", () => {
            const mousePosition = input_1.Input.mousePosition;
            const containerPos = new Phaser.Math.Vector2(container.x, container.y);
            const isInside = input_1.Input.isPointInsideRect(input_1.Input.mousePosition, containerPos, this.size);
            if (isInside) {
                const xDiff = mousePosition.x - containerPos.x;
                const xPos = xDiff / this.size.x;
                this.currentLength = xPos * this.totalLength;
                console.log(`timebar changed length to ${this.currentLength} / ${this.totalLength}`);
                this.events.emit("changedcurrentlength", this.currentLength);
            }
        });
    }
    update() {
        this.updateIndicatorPosition();
        this.timeText.setText((0, utils_1.msToTime)(this.currentLength * 1000));
    }
    updateIndicatorPosition() {
        const indicator = this.indicator;
        const x = this.currentLength / this.totalLength * this.size.x;
        const y = 5;
        indicator.setPosition(x, y);
    }
}
exports.Timebar = Timebar;
