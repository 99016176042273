"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PACKET_TYPE = void 0;
var PACKET_TYPE;
(function (PACKET_TYPE) {
    PACKET_TYPE[PACKET_TYPE["PACKET_EVENT"] = 0] = "PACKET_EVENT";
    PACKET_TYPE[PACKET_TYPE["PACKET_MATCH_STATUS_CHANGE"] = 1] = "PACKET_MATCH_STATUS_CHANGE";
    PACKET_TYPE[PACKET_TYPE["PACKET_MATCH_DATA_TO_START_GAME"] = 2] = "PACKET_MATCH_DATA_TO_START_GAME";
    PACKET_TYPE[PACKET_TYPE["PACKET_MATCH_CONFIRM_START_GAME"] = 3] = "PACKET_MATCH_CONFIRM_START_GAME";
    PACKET_TYPE[PACKET_TYPE["PACKET_PAD_DOWN_OR_UP"] = 4] = "PACKET_PAD_DOWN_OR_UP";
    PACKET_TYPE[PACKET_TYPE["PACKET_NOTE_MISS"] = 5] = "PACKET_NOTE_MISS";
    PACKET_TYPE[PACKET_TYPE["PACKET_FORCE_FINISH"] = 6] = "PACKET_FORCE_FINISH";
})(PACKET_TYPE || (exports.PACKET_TYPE = PACKET_TYPE = {}));
