"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GameLogic = exports.eGameLogicEvents = exports.PadData = exports.NoteData = exports.eNoteHitGood = void 0;
const baseObject_1 = require("../../utils/baseObject");
const eventHandler_1 = require("../../utils/eventHandler");
const interval_1 = require("../../utils/interval");
const utils_1 = require("../../utils/utils");
const gameSettings_1 = require("../constants/gameSettings");
const matchData_1 = require("./matchData");
var eNoteHitGood;
(function (eNoteHitGood) {
    eNoteHitGood[eNoteHitGood["HIT_PERFECT"] = 0] = "HIT_PERFECT";
    eNoteHitGood[eNoteHitGood["HIT_GOOD"] = 1] = "HIT_GOOD";
    eNoteHitGood[eNoteHitGood["HIT_OK"] = 2] = "HIT_OK";
    eNoteHitGood[eNoteHitGood["HIT_BAD"] = 3] = "HIT_BAD";
    eNoteHitGood[eNoteHitGood["HIT_NOT_ON_TIME"] = 4] = "HIT_NOT_ON_TIME";
})(eNoteHitGood || (exports.eNoteHitGood = eNoteHitGood = {}));
class NoteData {
    constructor(gameLogic, padIndex, songNote) {
        this.hitted = false;
        this.missed = false;
        this.gameLogic = gameLogic;
        this.padIndex = padIndex;
        this.songNote = songNote;
    }
    isInGameField() {
        if (!this.hasNotePassedStartOfGameField())
            return false;
        if (!this.isDragPassedEndOfGameField())
            return true;
        return false;
    }
    isDragPassedEndOfGameField() {
        const time = this.gameLogic.songTime;
        const end = this.getTimeOfEndOfGameField();
        if (time > end + this.songNote.dragTime) {
            return true;
        }
        return false;
    }
    hasNotePassedStartOfGameField() {
        const time = this.gameLogic.songTime;
        const start = this.songNote.time - 3000;
        if (time >= start) {
            return true;
        }
        return false;
    }
    hasPassedEndGameField() {
        const time = this.gameLogic.songTime;
        const end = this.getTimeOfEndOfGameField();
        return time > end;
    }
    getTimeOfEndOfGameField() {
        const end = this.songNote.time + 200;
        return end;
    }
}
exports.NoteData = NoteData;
class PadData {
    constructor(index) {
        this.index = index;
    }
}
exports.PadData = PadData;
var eGameLogicEvents;
(function (eGameLogicEvents) {
    eGameLogicEvents["EVENT_NOTE_HIT"] = "EVENT_NOTE_HIT";
    eGameLogicEvents["EVENT_BREAK_COMBO"] = "EVENT_BREAK_COMBO";
    eGameLogicEvents["EVENT_COMBO_REWARD"] = "EVENT_COMBO_REWARD";
    eGameLogicEvents["EVENT_PAD_BEGIN_DRAG"] = "EVENT_PAD_BEGIN_DRAG";
    eGameLogicEvents["EVENT_PAD_END_DRAG"] = "EVENT_PAD_END_DRAG";
})(eGameLogicEvents || (exports.eGameLogicEvents = eGameLogicEvents = {}));
const scoreMap = new Map([
    [eNoteHitGood.HIT_PERFECT, 100],
    [eNoteHitGood.HIT_GOOD, 80],
    [eNoteHitGood.HIT_OK, 50],
    [eNoteHitGood.HIT_BAD, 10],
    [eNoteHitGood.HIT_NOT_ON_TIME, 0],
]);
class GameLogic extends baseObject_1.BaseObject {
    constructor() {
        super(...arguments);
        this.money = 0;
        this.accumulatedMoney = 0;
        this.combo = 0;
        this.score = 0;
        this.events = new eventHandler_1.EventHandler();
        this.pads = [];
        this.notes = [];
        this.songTime = 0;
        this.demoSongDuration = undefined;
        this.matchData = {
            status: matchData_1.eMatchStatus.NONE,
            matchId: "",
            songId: "",
            userId: "",
            betValue: 0
        };
    }
    createAll() {
        this.createNotes();
        this.createPads();
    }
    createPads() {
        const numOfPads = 5;
        for (let i = 0; i < numOfPads; i++) {
            const padData = new PadData(i);
            this.pads.push(padData);
        }
    }
    createNotes() {
        if (this.notes.length > 0) {
            throw "GameLogic: Alreay created notes";
        }
        const song = this.song;
        for (const songNote of song.notes) {
            for (const padIndex of songNote.pads) {
                const note = new NoteData(this, padIndex, songNote);
                this.notes.push(note);
            }
        }
        this.log("Created " + this.notes.length + " notes");
    }
    missNote() {
    }
    processPadDown(padIndex) {
        //this.log("pad down", padIndex);
        const note = this.getClosestNoteForPad(padIndex);
        if (note) {
            if (!note.hitted) {
                const time = this.songTime;
                const noteTime = note.songNote.time;
                const distanceInMs = noteTime - time;
                if (distanceInMs < 500) {
                    const hitType = this.getHowGoodNoteIs(distanceInMs);
                    this.log(`note hit at ${time}, distanceInMs=${distanceInMs}`);
                    const countAsHit = hitType != eNoteHitGood.HIT_NOT_ON_TIME;
                    if (countAsHit) {
                        this.onNoteHit(note, hitType, false);
                    }
                    else {
                        this.breakCombo();
                    }
                    return { hitType: hitType, note: note };
                }
            }
        }
        return undefined;
    }
    processPadUp(padIndex) {
        const pad = this.pads[padIndex];
        if (!pad.draggingNote)
            return;
        const note = pad.draggingNote;
        const time = this.songTime;
        const end = note.songNote.time + note.songNote.dragTime;
        //console.log(`start: ${note.songNote.time}`);
        //console.log(`end: ${note.songNote.time + note.songNote.dragTime}`);
        //console.log(`current: ${time}`);
        const distanceInMs = end - time;
        //console.log(`distance: (${distanceInMs}ms)`);
        const hitType = this.getHowGoodNoteIs(distanceInMs);
        //const distance = GameScene.Instance.notes.getDistanceFromMs(distanceInMs);
        //const countAsHit = hitType != eNoteHitGood.HIT_NOT_ON_TIME;
        pad.draggingNote = undefined;
        this.events.emit(eGameLogicEvents.EVENT_PAD_END_DRAG, note.padIndex);
        this.onNoteHit(note, hitType, true);
    }
    onNoteHit(note, hitType, isEndDrag) {
        //this.log("onNoteHit ", note, isEndDrag);
        var _a;
        if (isEndDrag) {
            if (hitType == eNoteHitGood.HIT_NOT_ON_TIME) {
                this.log("drag released off timing, breaking combo");
                this.breakCombo();
                return;
            }
        }
        note.hitted = true;
        if (((_a = this._prevHitSongNote) === null || _a === void 0 ? void 0 : _a.songNote) != note.songNote) {
            this._prevHitSongNote = note;
            this.combo++;
            this.log("combo " + this.combo);
            const notesToReward = gameSettings_1.gameSettings.comboAward;
            var betValue = this.matchData.betValue;
            var maxGanho = betValue * 10;
            var notas = this.notes.length;
            var porNota = maxGanho / notas;
            this.accumulatedMoney += porNota;
            if (this.combo % notesToReward == 0 && this.combo != 0) {
                this.money += this.accumulatedMoney;
                this.accumulatedMoney = 0;
                this.events.emit(eGameLogicEvents.EVENT_COMBO_REWARD, note, hitType);
                this.log("Combo REWARD!");
            }
        }
        const addScore = scoreMap.get(hitType);
        if (addScore)
            this.score += addScore;
        this.events.emit(eGameLogicEvents.EVENT_NOTE_HIT, note, hitType);
        if (!isEndDrag) {
            if (note.songNote.dragTime > 0) {
                const pad = this.pads[note.padIndex];
                pad.draggingNote = note;
                this.events.emit(eGameLogicEvents.EVENT_PAD_BEGIN_DRAG, note.padIndex, note);
                //this.startDrag(note);
            }
        }
    }
    breakCombo() {
        //this.log("breakCombo")
        this.events.emit(eGameLogicEvents.EVENT_BREAK_COMBO);
        this.combo = 0;
        this.accumulatedMoney = 0;
        // remove money
        var betValue = this.matchData.betValue;
        var maxGanho = betValue * 10;
        var notas = this.notes.length;
        var porNota = maxGanho / notas;
        this.money -= porNota * 3; // multiplicado por x2, a cada erro
        if (this.money < 0)
            this.money = 0;
        //
    }
    getHowGoodNoteIs(ms) {
        //this.log("how good is", ms);
        const makeInterval = (ms) => {
            const interval = {
                from: -ms / 2,
                to: ms / 2
            };
            return interval;
        };
        if ((0, interval_1.isNumberBetweenInverval)(ms, makeInterval(100)))
            return eNoteHitGood.HIT_PERFECT;
        if ((0, interval_1.isNumberBetweenInverval)(ms, makeInterval(120)))
            return eNoteHitGood.HIT_GOOD;
        if ((0, interval_1.isNumberBetweenInverval)(ms, makeInterval(150)))
            return eNoteHitGood.HIT_OK;
        if ((0, interval_1.isNumberBetweenInverval)(ms, makeInterval(250)))
            return eNoteHitGood.HIT_BAD;
        return eNoteHitGood.HIT_NOT_ON_TIME;
    }
    getClosestNoteForPad(padIndex) {
        let closestDistance = Infinity;
        let closestNote = undefined;
        for (const note of this.notes) {
            if (!note.isInGameField())
                continue;
            if (note.padIndex != padIndex)
                continue;
            if (note.hitted)
                continue;
            if (note.missed)
                continue;
            const time = this.songTime;
            const distance = note.songNote.time - time;
            if (distance < closestDistance) {
                closestNote = note;
                closestDistance = distance;
            }
        }
        return closestNote;
    }
    printBalanceInfo() {
        this.log(`balance: ${this.money} (+${this.accumulatedMoney})`);
    }
    getNotesHitted() {
        return this.notes.filter(note => note.hitted);
    }
    getNotesMissed() {
        return this.notes.filter(note => note.missed);
    }
    getAccuracy() {
        const hittedNotes = this.getNotesHitted().length;
        const missedNotes = this.getNotesMissed().length;
        const totalNotes = hittedNotes + missedNotes;
        let hitRatio = (0, utils_1.clamp)(hittedNotes / totalNotes, 0, 1);
        if (Number.isNaN(hitRatio))
            hitRatio = 0;
        return hitRatio;
    }
    getMoneyEarned() {
        return this.money - this.matchData.betValue;
    }
}
exports.GameLogic = GameLogic;
