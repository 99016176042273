"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DebugText = void 0;
const mainScene_1 = require("../../game/scenes/mainScene");
const threeScene_1 = require("../three/threeScene");
class DebugText {
    constructor(title) {
        this.lines = new Map();
        this.position = new Phaser.Math.Vector2(0, 0);
        this.setLine("title", title);
    }
    createDebugText() {
        const scene = mainScene_1.MainScene.Instance;
        this.text = scene.add.text(0, 0, 'DEBUG_TEXT', { font: '16px Arial', color: '#ffff00' });
        this.text.setOrigin(0.5, 0);
        scene.layerHud.add(this.text);
    }
    setLine(key, text) {
        this.lines.set(key, text);
    }
    set3DPosition(position) {
        const screenPosition = threeScene_1.ThreeScene.projectToScreen(position);
        this.position.x = screenPosition.x;
        this.position.y = screenPosition.y;
    }
    update() {
        const text = this.text;
        if (text) {
            let str = "";
            for (const v of this.lines.values()) {
                str += `${v}\n`;
            }
            //text.setText(`${this.name} (${screenPosition.x}, ${screenPosition.y})`);
            text.setText(str);
            text.setPosition(this.position.x, this.position.y);
            //text.setPosition(400, 300);
        }
    }
    destroy() {
        if (this.text) {
            this.text.destroy();
            this.text = undefined;
        }
    }
}
exports.DebugText = DebugText;
