"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ThreeScene = void 0;
const phaser_extension_1 = require("@enable3d/phaser-extension");
const THREE = __importStar(require("three"));
const gameface_1 = require("../../game/gameface/gameface");
const phaser3dObject_1 = require("./phaser3dObject");
const utils_1 = require("../utils");
class ThreeScene extends phaser_extension_1.Scene3D {
    constructor() {
        super({ key: 'ThreeScene' });
        ThreeScene.Instance = this;
    }
    init() {
        this.accessThirdDimension();
    }
    create() {
        this.third.warpSpeed("-ground");
        const camera = this.third.camera;
        camera.fov = 60;
        const topView = false;
        const isMobile = (0, utils_1.getIsMobile)();
        if (topView) {
            camera.position.set(0, 10, 6);
            camera.lookAt(0, 0, 6);
        }
        else {
            //const camera = ThreeScene.Instance.third.camera
            //camera.position.set(0, 3, 8);
            //camera.lookAt(0, 0, 2.5);
            //camera.updateProjectionMatrix();
            if (isMobile) {
                camera.fov = 60;
                camera.position.set(0, 3.5, 8.5);
                camera.lookAt(0, 0, 1.5);
                camera.updateProjectionMatrix();
            }
            else {
                camera.fov = 60;
                camera.position.set(0, 2, 7.5);
                camera.lookAt(0, 0, 3.5);
                camera.updateProjectionMatrix();
            }
        }
        //this.third.renderer.setSize(200, 200, true);
        camera.updateProjectionMatrix();
        /*
        var camera = ThreeScene.Instance.third.camera;
        camera.fov = 60;
        camera.position.set(0, 3, 8);
        camera.lookAt(0, 0, 2.5);
        camera.updateProjectionMatrix();
        */
        /*
        const box = this.third.physics.add.box({x: 0, y: 5});
        ThreeScene.addPhaser3DObject(box);
        this.box = box;

        this.third.add.box({x: 0, y: 2});
        */
    }
    update() {
        for (const object of ThreeScene.phaser3dObjects)
            object.update();
    }
    static projectToScreen(position) {
        const scene = ThreeScene.Instance;
        const size = gameface_1.Gameface.Instance.getGameSize();
        const vector = position.clone().project(scene.third.camera);
        const widthHalf = size.x / 2;
        const heightHalf = size.y / 2;
        const screenPosition = new THREE.Vector2((vector.x * widthHalf) + widthHalf, -(vector.y * heightHalf) + heightHalf);
        return screenPosition;
    }
    static getDistanceFromCamera(position) {
        const scene = ThreeScene.Instance;
        const cameraPosition = scene.third.camera.position;
        return position.distanceTo(cameraPosition);
    }
    static addPhaser3DObject(threeObject) {
        const object = new phaser3dObject_1.Phaser3DObject(threeObject);
        this.phaser3dObjects.push(object);
        return object;
    }
}
exports.ThreeScene = ThreeScene;
ThreeScene.phaser3dObjects = [];
