"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EditorScene = void 0;
const gameface_1 = require("../../gameface/gameface");
const addNote_1 = require("./addNote");
const bmpMeter_1 = require("./bmpMeter");
const timebar_1 = require("./timebar");
const gameScene_1 = require("../gameScene/gameScene");
const hud_1 = require("../../hud/hud");
const noteOptions_1 = require("./noteOptions");
const bpmOffsetsPanel_1 = require("./bpmOffsetsPanel");
class EditorScene extends Phaser.Scene {
    constructor() {
        super({});
        EditorScene.Instance = this;
        this.timebar = new timebar_1.Timebar();
        this.bpmMeter = new bmpMeter_1.BPMMeter();
    }
    setSong(song) {
        this.song = Object.assign({}, song);
        this.bpmMeter.bpms = song.bpms;
        this.bpmMeter.offset = song.offset;
        gameScene_1.GameScene.Instance.soundPlayer.startSong(this.song);
        this.exportSong();
    }
    create() {
        return __awaiter(this, void 0, void 0, function* () {
            console.log("create");
            gameface_1.Gameface.Instance.sceneManager.startScene(gameScene_1.GameScene);
            gameScene_1.GameScene.Instance.soundPlayer.autoFinishWhenNoMoreNotes = false;
            this.bpmMeter.bpmKeyCounter.setupEvents();
            this.timebar.create(this);
            hud_1.Hud.addToHudLayer(this.timebar.container);
            this.timebar.events.on("changedcurrentlength", (currentLength) => {
                const audio = gameScene_1.GameScene.Instance.soundPlayer.audio;
                //const newTime = Math.round(currentLength);
                const newTime = parseFloat(currentLength.toFixed(2));
                console.log('Move timebar to', newTime);
                audio.currentTime = newTime;
            });
            this.bpmMeter.create(this);
            const addNote = hud_1.Hud.addButton("Add note", 50, 180, 80, 50, "button");
            addNote.onClick = () => {
                EditorScene.showNoteOptionsButton = false;
                this._addNotePanel = new addNote_1.AddNote(this);
                this._addNotePanel.onClose = () => {
                    this._addNotePanel = undefined;
                    EditorScene.showNoteOptionsButton = true;
                };
            };
            const snap = hud_1.Hud.addButton("Snap", 50, 240, 80, 50, "button");
            snap.onClick = () => {
                this.snapToClosestBeatDivisior();
            };
            const offsets = hud_1.Hud.addButton("BPM Offsets", 150, 240, 100, 50, "button");
            offsets.onClick = () => {
                const panel = new bpmOffsetsPanel_1.BPMOffsetsPanel(this);
                this._bpmOffsetsPanel = panel;
                panel.onClose = () => {
                    this._bpmOffsetsPanel = undefined;
                };
            };
            const exportSongBtn = hud_1.Hud.addButton("Export", 250, 240, 100, 50, "button");
            exportSongBtn.onClick = () => {
                const newWindow = window.open("", "_blank");
                // Verifica se a nova janela não foi bloqueada pelo navegador
                if (newWindow) {
                    const song = EditorScene.Instance.song;
                    //const formatter = new JSONFormatter(song);
                    //document.body.appendChild(formatter.render());
                    newWindow.document.write(`<pre>${JSON.stringify(song)}</pre>`);
                }
                else {
                    alert('Popup blocked!');
                }
            };
            const speedList = [1, 0.75, 0.5, 0.25, 0.1];
            let x = 25;
            for (const speed of speedList) {
                const setSpeed = hud_1.Hud.addButton(`x${speed.toFixed(2)}`, x, 290, 50, 30, "button");
                setSpeed.onClick = () => {
                    this.setPlaybackSpeed(speed);
                };
                x += 50;
            }
            const bpmOptions = hud_1.Hud.addOptions(100);
            bpmOptions.addOption("1/2", 1 / 2);
            bpmOptions.addOption("1/3", 1 / 3);
            bpmOptions.addOption("1/4", 1 / 4);
            bpmOptions.addOption("1/5", 1 / 5);
            bpmOptions.addOption("1/8", 1 / 8);
            bpmOptions.addOption("1/12", 1 / 12);
            bpmOptions.onOptionChange = () => {
                this.bpmMeter.bpmDivision = bpmOptions.getCurrentOptionValue();
            };
            this._bpmOptions = bpmOptions;
            // KEYBOARD KEYS ------------------------
            // SPACE
            this.input.keyboard.on('keydown-SPACE', (event) => {
                const soundPlayer = gameScene_1.GameScene.Instance.soundPlayer;
                const audio = soundPlayer.audio;
                if (audio.paused) {
                    soundPlayer.resumeSound();
                }
                else {
                    soundPlayer.pauseSound();
                }
                //audio.currentTime = this.timebar.currentLength;
            });
            // UP / DOWN
            this.input.keyboard.on('keydown-UP', (event) => {
                this.moveSoundBy(1000);
            });
            this.input.keyboard.on('keydown-DOWN', (event) => {
                this.moveSoundBy(-1000);
            });
        });
    }
    update(time, delta) {
        var _a;
        this.timebar.update();
        this.bpmMeter.update();
        (_a = this._bpmOffsetsPanel) === null || _a === void 0 ? void 0 : _a.update();
        this._bpmOptions.update();
        const soundPlayer = gameScene_1.GameScene.Instance.soundPlayer;
        this.timebar.currentLength = soundPlayer.getCurrentSoundPosition() / 1000;
        this.timebar.totalLength = soundPlayer.getAudioDuration() / 1000;
    }
    setPlaybackSpeed(speed) {
        gameScene_1.GameScene.Instance.soundPlayer.audio.playbackRate = speed;
    }
    /*
    1 = next beat
    0 = closest beat
    -1 = previous beat
    */
    snapToClosestBeatDivisior() {
        const soundPlayer = gameScene_1.GameScene.Instance.soundPlayer;
        const time = soundPlayer.getCurrentSoundPosition();
        const closestBpmBar = EditorScene.Instance.bpmMeter.getClosestBpmBar(time);
        let snapTo = closestBpmBar.timeMs;
        soundPlayer.audio.currentTime = snapTo / 1000;
        /*
        const soundPlayer = GameScene.Instance.soundPlayer;
        const numDivisions = (1 / this.bpmMeter.bpmDivision) + 1;

        const soundTime = soundPlayer.getAudioCurrentTime();

        let snapToTime = soundTime; //default
        let closestTimeDiff = Infinity;
        
        console.log(`soundTime=${soundTime}`);
        
        for(let i = -1; i < numDivisions; i++)
        {
            const t = this.bpmMeter.getCurrentBeatTime((i * this.bpmMeter.bpmDivision));

            const timeDiff = Math.abs(soundTime - t);

            if(direction == 1)
            {
                if(t <= soundTime) continue;
                if(timeDiff < 1) continue;
            }

            if(direction == -1)
            {
                if(t >= soundTime) continue;
                if(timeDiff < 1) continue;
            }

            
            if(timeDiff < closestTimeDiff)
            {
                closestTimeDiff = timeDiff;
                snapToTime = t;
            }
            
            console.log(`t=${t}`);
        }
        
        soundPlayer.audio!.currentTime = snapToTime / 1000;
        */
    }
    moveSoundBy(offsetTime) {
        const soundPlayer = gameScene_1.GameScene.Instance.soundPlayer;
        const time = soundPlayer.getRealAudioCurrentTime();
        const audio = soundPlayer.audio;
        const soundIsPlaying = !audio.paused;
        //const newPosition = parseFloat(((time + offsetTime) / 1000).toFixed(0));
        let newPosition = ((time + offsetTime) / 1000);
        //const newPosition = ((time + offsetTime) / 1000);
        console.log(`Trying to move to ${newPosition}`);
        newPosition = parseFloat(newPosition.toFixed(2));
        console.log(`Move to ${newPosition}`);
        audio.pause();
        if (soundIsPlaying)
            audio.play();
        audio.currentTime = newPosition;
    }
    exportSong() {
        const song = this.song;
        let str = "bpms: [\n";
        for (const bpmChange of song.bpms) {
            str += `    {time: ${bpmChange.time}, bpm: ${bpmChange.bpm}},\n`;
        }
        str += "],\n";
        str += "notes: [\n";
        for (const songNote of song.notes) {
            str += `    {time: ${songNote.time}, pads: ${JSON.stringify(songNote.pads)}, dragTime: ${songNote.dragTime}},\n`;
        }
        str += "]";
        console.log(str);
    }
    openNoteOptions(time) {
        const song = this.song;
        for (const songNote of song.notes) {
            if (songNote.time == time) {
                EditorScene.showNoteOptionsButton = false;
                const noteOptions = new noteOptions_1.NoteOptions(this, songNote);
                this._noteOptions = noteOptions;
                this._noteOptions.onClose = () => {
                    this._noteOptions = undefined;
                    EditorScene.showNoteOptionsButton = true;
                };
                break;
            }
        }
    }
    deleteNote(songNote) {
        const song = this.song;
        let deleteIndex = song.notes.indexOf(songNote);
        if (deleteIndex >= 0) {
            song.notes.splice(deleteIndex, 1);
            gameScene_1.GameScene.Instance.soundPlayer.recreateNotes();
        }
    }
    findNextNote(songNote) {
        const song = this.song;
        let sortedNotes = [];
        for (const findSongNote of song.notes)
            sortedNotes.push(findSongNote);
        sortedNotes = sortedNotes.sort((a, b) => a.time - b.time);
        for (const findSongNote of sortedNotes) {
            console.log(findSongNote.time);
        }
        console.log(`sort:`);
        const originalIndex = sortedNotes.indexOf(songNote);
        sortedNotes = sortedNotes.filter((a, index) => {
            return index == originalIndex + 1;
        });
        if (sortedNotes.length == 0) {
            return;
        }
        const nextNote = sortedNotes[0];
        return nextNote;
    }
    makeNoteSlider(songNote) {
        console.log(`Make note slider:`, songNote);
        const nextNote = this.findNextNote(songNote);
        if (!nextNote) {
            console.error("Could not find next note");
            return;
        }
        const diff = nextNote.time - songNote.time;
        songNote.dragTime = diff;
        this.deleteNote(nextNote);
        gameScene_1.GameScene.Instance.soundPlayer.recreateNotes();
    }
    removeSlider(songNote) {
        songNote.dragTime = 0;
        gameScene_1.GameScene.Instance.soundPlayer.recreateNotes();
    }
    static isRunning() {
        return gameface_1.Gameface.Instance.sceneManager.hasSceneStarted(EditorScene);
    }
}
exports.EditorScene = EditorScene;
EditorScene.showNoteOptionsButton = true;
