"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Debug = void 0;
class Debug {
    static log(tag, ...args) {
        let tagStr = tag;
        if (args.length == 0) {
            tagStr = "Debug";
            args = [tag];
        }
        if (!Debug.useColor) {
            console.log.apply(this, [`[${tag}]`].concat(args));
            return;
        }
        console.log.apply(this, [`%c${tagStr}`, "color: #0058B2"].concat(args));
    }
}
exports.Debug = Debug;
Debug.useColor = true;
