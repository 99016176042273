"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InfoText = exports.InfoTextPanelColor = void 0;
const baseObject_1 = require("../../../utils/baseObject");
const graph_1 = require("../../../utils/graph");
const utils_1 = require("../../../utils/utils");
const gameSettings_1 = require("../../constants/gameSettings");
const gameface_1 = require("../../gameface/gameface");
const hud_1 = require("../../hud/hud");
var InfoTextPanelColor;
(function (InfoTextPanelColor) {
    InfoTextPanelColor[InfoTextPanelColor["PANEL_RED"] = 0] = "PANEL_RED";
    InfoTextPanelColor[InfoTextPanelColor["PANEL_GREEN"] = 1] = "PANEL_GREEN";
})(InfoTextPanelColor || (exports.InfoTextPanelColor = InfoTextPanelColor = {}));
class InfoText extends baseObject_1.BaseObject {
    constructor() {
        super();
        this.scaleGraph = new graph_1.Graph();
        this.visibleTime = 0;
        this.missTexts = [
            "Quase lá!\nSó mais uma tentativa!",
            "Relaxa, a próxima você\nacerta e vira o jogo!",
            "Errou? Nada que uma\nsegunda chance não resolva!",
            "Sacode a poeira e tenta\nde novo, você consegue!",
            "Não desiste,\na vitória tá logo ali!"
        ];
        this.hitTexts = [
            "Uau! Continua assim que\no show só tá começando!",
            "Tá mandando bem!\nBora pro próximo nível?",
            "Arrasou! Mais " + gameSettings_1.gameSettings.comboAward + " e\nvocê vira lenda!",
            "Você tá pegando o jeito!\nQuer ver até onde vai?",
            "Tá brilhando! Continua que\no sucesso é garantido!"
        ];
        this.scaleGraph.add(0.5, 100);
        this.scaleGraph.add(1.3, 100);
        this.scaleGraph.add(1.0, 0);
        window.game.infoText = this;
    }
    create(scene) {
        const gameSize = gameface_1.Gameface.Instance.getGameSize();
        const containerY = (0, utils_1.getIsMobile)() ? 300 : 260;
        const container = scene.add.container(gameSize.x / 2, gameSize.y / 2 - containerY);
        hud_1.Hud.addToHudLayer(container);
        this.container = container;
        //const background = scene.add.nineslice(0, 0, 20, 20, "blue_panel", 20);
        const background = scene.add.image(0, 0, "grey_panel2");
        background.setOrigin(0.5);
        container.add(background);
        this.background = background;
        //const background = scene.add.image(0, 0, "blue_panel");
        //container.add(background);
        //this.background = background;
        const textContainer = scene.add.container();
        container.add(textContainer);
        this.textContainer = textContainer;
        const fontSize = (0, utils_1.getIsMobile)() ? 40 : 30;
        const text = scene.add.text(0, 0, 'INFO_TEXT');
        text.setFontFamily('Arial');
        text.setFontSize(fontSize);
        text.setColor('#ffffff');
        text.setOrigin(0.5);
        text.setAlign("center");
        text.setStroke('#000000', 8);
        this.text = text;
        textContainer.add(text);
        window.game.infoText = this;
    }
    setPanelColor(color) {
        this.background.setTint(color);
    }
    update(delta) {
        this.scaleGraph.changeCurrentTimeBy(delta);
        if (this.visibleTime > 0) {
            this.visibleTime -= delta;
            if (this.visibleTime < 0)
                this.visibleTime = 0;
        }
        const textBounds = this.text.getBounds();
        let w = textBounds.width + 30;
        if (w < 300)
            w = 300;
        this.background.setDisplaySize(w, textBounds.height + 30);
        this.textContainer.setScale(this.scaleGraph.getValue());
        this.container.setVisible(this.visibleTime > 0 || this.visibleTime == -1);
    }
    setText(str, time) {
        this.visibleTime = time;
        this.text.setText(str);
        this.scaleGraph.currentTime = 0;
    }
    setWhiteText(str, time) {
        this.setPanelColor(0xffffff);
        this.setText(str, time);
    }
    setGreenText(str, time) {
        this.setPanelColor(0x00B214);
        this.setText(str, time);
    }
    setRedText(str, time) {
        this.setPanelColor(0xD84B4B);
        this.setText(str, time);
    }
    setColor(color) {
        const hexString = color.toString(16);
        this.text.setColor(`#${hexString}`);
    }
    showRandomHitMessage() {
        const message = this.hitTexts[(0, utils_1.randomIntFromInterval)(0, this.hitTexts.length - 1)];
        this.setGreenText(message, 1500);
    }
    showRandomMissMessage() {
        const message = this.missTexts[(0, utils_1.randomIntFromInterval)(0, this.missTexts.length - 1)];
        this.setRedText(message, 1500);
    }
}
exports.InfoText = InfoText;
