"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Button = void 0;
class Button {
    get container() { return this._container; }
    constructor(scene, text, x, y, width, height, texture) {
        this._isPointerOver = false;
        this.isPointerDown = false;
        this._width = width;
        this._height = height;
        const container = this._container = scene.add.container();
        /*
        const background = this._backgrond = scene.add.nineslice(0, 0, width, height, texture, offset).setOrigin(0.5);
        background.setInteractive();
        container.add(background);
        */
        const background = this._backgrond = scene.add.image(0, 0, texture);
        background.setDisplaySize(width, height);
        background.setInteractive();
        container.add(background);
        const textgo = this._text = scene.add.text(0, 0, text, { font: '16px Arial' });
        //text3.setFontSize(12);
        //text3.setStroke("#55330D", 10)
        textgo.setOrigin(0.5);
        container.add(textgo);
        const self = this;
        const pointerOverScale = 1.05;
        background.on('pointerover', function (pointer) {
            var _a;
            self.setScale(pointerOverScale, pointerOverScale);
            textgo.setScale(pointerOverScale);
            (_a = self.onPointerOver) === null || _a === void 0 ? void 0 : _a.call(self);
            self._isPointerOver = true;
        });
        background.on('pointerout', function (pointer) {
            var _a;
            self.setScale(1, 1);
            textgo.setScale(1);
            (_a = self.onPointerOut) === null || _a === void 0 ? void 0 : _a.call(self);
            self._isPointerOver = false;
        });
        background.on('pointerdown', function (pointer) {
            //alert("down")
            self.isPointerDown = true;
            //Input.simulatePointerUp(pointer);
        });
        background.on('pointerup', function (pointer) {
            var _a;
            //alert("up")
            self.isPointerDown = false;
            (_a = self.onClick) === null || _a === void 0 ? void 0 : _a.call(self);
            //Input.simulatePointerUp(pointer);
        });
        container.setPosition(x, y);
        container.once("destroy", () => {
            this.onDestroy();
        });
    }
    onBeginDragEv() {
        var _a;
        if (!this._isPointerOver)
            return;
        (_a = this.onBeginDrag) === null || _a === void 0 ? void 0 : _a.call(this);
    }
    setScale(sx, sy) {
        var _a;
        (_a = this._backgrond) === null || _a === void 0 ? void 0 : _a.setDisplaySize(this._width * sx, this._height * sy);
    }
    destroy() {
        var _a, _b, _c;
        (_a = this._backgrond) === null || _a === void 0 ? void 0 : _a.destroy();
        (_b = this._text) === null || _b === void 0 ? void 0 : _b.destroy();
        (_c = this._container) === null || _c === void 0 ? void 0 : _c.destroy();
        this.onDestroy();
    }
    onDestroy() {
        this._backgrond = undefined;
        this._text = undefined;
        this._container = undefined;
    }
}
exports.Button = Button;
