"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NoteOptions = void 0;
const gameface_1 = require("../../gameface/gameface");
const hud_1 = require("../../hud/hud");
const mainScene_1 = require("../mainScene");
const editorScene_1 = require("./editorScene");
class NoteOptions {
    constructor(scene, note) {
        this.note = note;
        const gameSize = gameface_1.Gameface.Instance.getGameSize();
        this.container = scene.add.container(0, 0);
        this.container.setPosition(gameSize.x / 2, gameSize.y / 2);
        mainScene_1.MainScene.Instance.layerHud.add(this.container);
        this.background = scene.add.rectangle(0, 0, 400, 400, 0xffffff);
        this.container.add(this.background);
        const deleteNote = hud_1.Hud.addButton("Remove note", 0, -50, 150, 50, "button");
        deleteNote.onClick = () => {
            var _a;
            this.destroy();
            editorScene_1.EditorScene.Instance.deleteNote(this.note);
            (_a = this.onClose) === null || _a === void 0 ? void 0 : _a.call(this);
        };
        this.container.add(deleteNote.container);
        const makeSlider = hud_1.Hud.addButton("Make slider", 0, 0, 150, 50, "button");
        makeSlider.onClick = () => {
            var _a;
            this.destroy();
            editorScene_1.EditorScene.Instance.makeNoteSlider(this.note);
            (_a = this.onClose) === null || _a === void 0 ? void 0 : _a.call(this);
        };
        this.container.add(makeSlider.container);
        const removeSlider = hud_1.Hud.addButton("Remove slider", 0, 50, 150, 50, "button");
        removeSlider.onClick = () => {
            var _a;
            this.destroy();
            editorScene_1.EditorScene.Instance.removeSlider(this.note);
            (_a = this.onClose) === null || _a === void 0 ? void 0 : _a.call(this);
        };
        this.container.add(removeSlider.container);
        const back = hud_1.Hud.addButton("> Back", 0, 100, 150, 50, "button");
        back.onClick = () => {
            var _a;
            this.destroy();
            (_a = this.onClose) === null || _a === void 0 ? void 0 : _a.call(this);
        };
        this.container.add(back.container);
    }
    destroy() {
        this.background.destroy();
        this.container.destroy();
        //this.addButton.destroy();
    }
}
exports.NoteOptions = NoteOptions;
