"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AssetLoad = exports.AssetType = exports.LoadState = void 0;
const Phaser = __importStar(require("phaser"));
const gameface_1 = require("../../game/gameface/gameface");
const loadScene_1 = require("../../game/scenes/loadScene");
const debug_1 = require("../debug/debug");
const assets_1 = require("../../game/constants/assets");
const audioManager_1 = require("../audioManager/audioManager");
var LoadState;
(function (LoadState) {
    LoadState[LoadState["NOT_ADDED"] = 0] = "NOT_ADDED";
    LoadState[LoadState["NOT_LOADED"] = 1] = "NOT_LOADED";
    LoadState[LoadState["LOADING"] = 2] = "LOADING";
    LoadState[LoadState["LOADED"] = 3] = "LOADED";
})(LoadState || (exports.LoadState = LoadState = {}));
var AssetType;
(function (AssetType) {
    AssetType[AssetType["IMAGE"] = 0] = "IMAGE";
    AssetType[AssetType["AUDIO"] = 1] = "AUDIO";
    AssetType[AssetType["FONT"] = 2] = "FONT";
    AssetType[AssetType["ATLAS"] = 3] = "ATLAS";
    AssetType[AssetType["TASK_BEFORE_LOAD"] = 4] = "TASK_BEFORE_LOAD";
    AssetType[AssetType["TASK_AFTER_LOAD"] = 5] = "TASK_AFTER_LOAD";
})(AssetType || (exports.AssetType = AssetType = {}));
class AssetLoad {
    static addImage(key, path) {
        console.log(`[AssetLoad] Add asset to load: ${key} (${path})`);
        const asset = {
            key: key,
            path: path,
            loadState: LoadState.NOT_ADDED,
            type: AssetType.IMAGE
        };
        this._assets.set(key, asset);
    }
    static addAudio(key, path) {
        console.log(`Add asset to load: ${key} (${path})`);
        const asset = {
            key: key,
            path: path,
            loadState: LoadState.NOT_ADDED,
            type: AssetType.AUDIO
        };
        this._assets.set(key, asset);
    }
    static addAtlas(key, path) {
        console.log(`Add asset to load: ${key} (${path})`);
        const asset = {
            key: key,
            path: path,
            loadState: LoadState.NOT_ADDED,
            type: AssetType.ATLAS
        };
        this._assets.set(key, asset);
    }
    static addTask(key, loadBefore, fn) {
        console.log(`Add task ${key} to load`);
        const asset = {
            key: key,
            path: "",
            loadState: LoadState.NOT_ADDED,
            type: loadBefore ? AssetType.TASK_BEFORE_LOAD : AssetType.TASK_AFTER_LOAD,
            fn: fn
        };
        this._assets.set(key, asset);
    }
    static addAssets() {
        this.addTask(`connect_server`, true, () => __awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve) => __awaiter(this, void 0, void 0, function* () {
                console.log("connecting to server");
                yield gameface_1.Gameface.Instance.network.connectAndWait();
                resolve();
            }));
        }));
        this.addTask(`load_songs`, true, () => __awaiter(this, void 0, void 0, function* () {
            console.log("load_songs called...");
            return new Promise((resolve) => __awaiter(this, void 0, void 0, function* () {
                console.log("beginLoad1");
                yield gameface_1.Gameface.Instance.beginLoad1();
                console.log("loading songs");
                for (const song of gameface_1.Gameface.Instance.songManager.getSongs()) {
                    const key = song.sound; //song1
                    const path = `songs/${song.sound}.mp3`;
                    //this.addAudio(key, path); //doesnt work
                    audioManager_1.AudioManager.addAudio(key, path);
                    //this.addImage(`${song.sound}_image`, `songs/${song.sound}.png`); //doesnt work
                }
                resolve();
            }));
        }));
        for (const asset of assets_1.imageAssets) {
            this.addImage(asset.key, asset.path);
        }
        for (const asset of assets_1.atlasAssets) {
            this.addAtlas(asset.key, asset.path);
        }
        for (const asset of assets_1.audioAssets) {
            //this.addAudio(asset.key, asset.path);
            audioManager_1.AudioManager.addAudio(asset.key, asset.path);
        }
    }
    static load() {
        return __awaiter(this, void 0, void 0, function* () {
            debug_1.Debug.log("AssetLoad", "Loading assets...");
            const scene = gameface_1.Gameface.Instance.sceneManager.startScene(loadScene_1.LoadScene);
            for (const asset of this._assets.values()) {
                scene.loadAsset(asset);
            }
            yield scene.startLoadingAssets();
            gameface_1.Gameface.Instance.sceneManager.removeScene(loadScene_1.LoadScene);
            loadScene_1.LoadScene.Instance = undefined;
            debug_1.Debug.log("AssetLoad", "Assets loaded!");
        });
    }
    static getAssetsUrl() {
        return `${location.protocol}//${location.host}/assets/`;
    }
    static getAssetByKey(key) {
        for (const asset of this._assets.values()) {
            if (asset.key == key)
                return asset;
        }
        return undefined;
    }
}
exports.AssetLoad = AssetLoad;
AssetLoad._assets = new Phaser.Structs.Map([]);
