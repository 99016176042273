"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BPMMeter = exports.BPMKeyCounter = void 0;
const audioManager_1 = require("../../../utils/audioManager/audioManager");
const gameScene_1 = require("../gameScene/gameScene");
const bpmBar_1 = require("./bpmBar");
class BPMKeyCounter {
    constructor() {
        this.lastHit = 0;
        this.averageHits = [];
    }
    setupEvents() {
        gameScene_1.GameScene.Instance.events.on("pad_down", () => {
            const time = gameScene_1.GameScene.Instance.soundPlayer.getCurrentSoundPosition();
            const lastHit = this.lastHit;
            this.lastHit = time;
            if (lastHit != 0) {
                const diff = Math.abs(time - lastHit);
                this.averageHits.push(diff);
                if (diff > 2000) {
                    this.averageHits = [];
                    this.lastHit = 0;
                }
            }
            let total = 0;
            for (const t of this.averageHits)
                total += t;
            let avg = total / this.averageHits.length;
            const beatsPerSecond = 1000 / avg;
            const beatsPerMinute = beatsPerSecond * 60;
            console.log(this.averageHits);
            console.log("avg", avg);
            console.log("beatsPerMinute", beatsPerMinute);
        });
    }
}
exports.BPMKeyCounter = BPMKeyCounter;
class BPMMeter {
    constructor() {
        this.bpms = [];
        this.offset = 15;
        this.playBpmSound = true;
        this.bpmDivision = 1 / 2;
        //public bpmBars: BPMBar[] = [];
        this.bpmDivisionBars = [];
        this._lastBpmBarPlayedTime = 0;
        this.bpmKeyCounter = new BPMKeyCounter();
    }
    create(scene) {
        this._audio = audioManager_1.AudioManager.playAudioWithVolume("bpm", 0.02);
        this._audio.pause();
    }
    update() {
        const time = gameScene_1.GameScene.Instance.soundPlayer.getCurrentSoundPosition();
        //const bpm = this.getBPMOfTime(time);
        //console.log(`time=${time.toFixed(1)} bpm=${bpm}`)
        var bpmBarIndex = 0;
        for (var i = 0; i < this.bpms.length; i++) {
            const bpmChange = this.bpms[i];
            const bpmChangeInterval = this.getBPMChangeInterval(bpmChange);
            const startTime = bpmChangeInterval.from;
            const endTime = bpmChangeInterval.to;
            const timeDuration = endTime - startTime;
            const beats = this.getHowManyBeatsInTimeDuration(bpmChange.bpm, timeDuration);
            //console.log("current", bpmChange)
            //console.log("next", nextBpmChange)
            //console.log(startTime, "->", endTime)
            //console.log("beats", beats)
            const minBeats = Math.floor(beats);
            for (var beatI = 0; beatI <= minBeats; beatI++) {
                const durationOfOneBeat = this.getTimeDurationOfOneBeat(bpmChange.bpm);
                const timePos = startTime + beatI * durationOfOneBeat;
                const bpmBar = this.createBpmBar(bpmBarIndex++);
                bpmBar.timeMs = timePos;
                bpmBar.image.tint = i % 2 == 0 ? 0xff0000 : 0x0000ff;
                bpmBar.scale = 1.5;
                bpmBar.isSubDivision = false;
                bpmBar.update();
                if (beatI == minBeats)
                    continue;
                const divideIn = 1 / this.bpmDivision;
                for (var beatDivI = 1; beatDivI < divideIn; beatDivI++) {
                    let divTimePos = timePos + (beatDivI * (durationOfOneBeat / divideIn));
                    const bpmBarDiv = this.createBpmBar(bpmBarIndex++);
                    bpmBarDiv.timeMs = divTimePos;
                    bpmBarDiv.scale = 0.5;
                    bpmBarDiv.image.tint = 0xffffff;
                    bpmBarDiv.isSubDivision = true;
                    bpmBarDiv.update();
                }
            }
        }
        const closestBpmBar = this.getClosestBpmBar(time);
        //console.log("closest", closestBpmBar.timeMs)
        //console.log("_lastBpmBarPlayed", this._lastBpmBarPlayedTime)
        if (closestBpmBar.timeMs != this._lastBpmBarPlayedTime) {
            if (time >= closestBpmBar.timeMs && !closestBpmBar.isSubDivision) {
                this._lastBpmBarPlayedTime = closestBpmBar.timeMs;
                console.log("changed");
                if (this.playBpmSound) {
                    this._audio.currentTime = 0;
                    this._audio.play();
                }
            }
        }
    }
    getBPMChangeInterval(bpmChange) {
        const index = this.bpms.indexOf(bpmChange);
        const nextBpmChange = this.bpms[index + 1];
        const soundFinishTime = gameScene_1.GameScene.Instance.soundPlayer.getAudioDuration();
        const startTime = bpmChange.time;
        const endTime = nextBpmChange ? nextBpmChange.time : soundFinishTime;
        const interval = {
            from: startTime,
            to: endTime
        };
        return interval;
    }
    getClosestBpmBar(time) {
        const bars = this.bpmDivisionBars.sort((a, b) => {
            const distanceA = this.getBpmBarDistanceFromTime(a, time);
            const distanceB = this.getBpmBarDistanceFromTime(b, time);
            return distanceA - distanceB;
        });
        const closestBpmBar = bars[0];
        //const distance = this.getBpmBarDistanceFromTime(closestBpmBar, time);
        return closestBpmBar;
    }
    getBpmBarDistanceFromTime(bpmBar, time) {
        return Math.abs(time - bpmBar.timeMs);
    }
    createBpmBar(index) {
        const scene = gameScene_1.GameScene.Instance;
        if (index < this.bpmDivisionBars.length) {
            return this.bpmDivisionBars[index];
        }
        const bpmBar = new bpmBar_1.BPMBar(scene);
        this.bpmDivisionBars.push(bpmBar);
        return bpmBar;
    }
    getTimeDurationOfOneBeat(bpm) {
        const beatsPerMinute = bpm;
        const beatsPerSecond = beatsPerMinute / 60;
        const beatDurationInOneSecond = 1 / beatsPerSecond;
        return beatDurationInOneSecond * 1000;
    }
    getBPMChangeOfTime(time) {
        let currentBpm = this.bpms[0];
        for (var i = 0; i < this.bpms.length; i++) {
            const bpm = this.bpms[i];
            if (bpm.time <= time)
                currentBpm = bpm;
            //console.log(i, bpm.time);
        }
        return currentBpm;
    }
    getBPMOfTime(time) {
        let bpmChange = this.getBPMChangeOfTime(time);
        return bpmChange.bpm;
    }
    getHowManyBeatsInTimeDuration(bpm, time) {
        const beatsPerMinute = bpm;
        const beatsPerSecond = beatsPerMinute / 60;
        const seconds = time / 1000;
        return beatsPerSecond * seconds;
    }
    getBeat(bpm, offset) {
        const perBeat = 60000 / bpm;
        let currentTime = gameScene_1.GameScene.Instance.soundPlayer.getCurrentSoundPosition();
        currentTime += offset;
        const beat = Math.floor(currentTime / perBeat);
        return beat;
    }
    getCurrentBeatTime(beatOffset) {
        return 0;
    }
}
exports.BPMMeter = BPMMeter;
