"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EventHandler = void 0;
class EventHandler {
    constructor() {
        this.events = {};
    }
    // Method to listen to an event
    on(event, callback) {
        if (!this.events[event]) {
            this.events[event] = [];
        }
        this.events[event].push(callback);
    }
    // Method to emit an event and call all its listeners
    emit(event, ...args) {
        const callbacks = this.events[event];
        if (callbacks) {
            callbacks.forEach((callback) => callback(...args));
        }
    }
    // Method to remove a specific callback for an event
    off(event, callback) {
        if (this.events[event]) {
            this.events[event] = this.events[event].filter(cb => cb !== callback);
        }
    }
    // Method to remove all listeners for an event
    offAll(event) {
        if (this.events[event]) {
            delete this.events[event];
        }
    }
}
exports.EventHandler = EventHandler;
