"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PacketListener = void 0;
class PacketListener {
    constructor() {
        this.functions = new Map();
    }
    emitReceivedPacketEvent(packet) {
        if (!this.functions.has(packet.type))
            return;
        const fns = this.functions.get(packet.type);
        for (const fn of fns) {
            fn(packet.data);
        }
        this.functions.delete(packet.type);
        console.log(`[PacketListener] There are ${0} functions listening for packet ${packet.type}`);
    }
    listen(type, callback) {
        if (!this.functions.has(type)) {
            this.functions.set(type, []);
        }
        const fns = this.functions.get(type);
        fns.push(callback);
        console.log(`[PacketListener] There are ${fns.length} functions listening for packet ${type}`);
    }
}
exports.PacketListener = PacketListener;
