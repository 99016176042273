"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BPMOffsetsPanel = exports.BPMChangeItem = exports.BPMEditPanel = void 0;
const interval_1 = require("../../../utils/interval");
const button_1 = require("../../../utils/ui/button");
const numberRange_1 = require("../../../utils/ui/numberRange");
const gameface_1 = require("../../gameface/gameface");
const hud_1 = require("../../hud/hud");
const gameScene_1 = require("../gameScene/gameScene");
const mainScene_1 = require("../mainScene");
const editorScene_1 = require("./editorScene");
class BPMEditPanel {
    constructor(scene, bpmChange) {
        const gameSize = gameface_1.Gameface.Instance.getGameSize();
        const container = scene.add.container(0, 0);
        container.setPosition(gameSize.x / 2, gameSize.y / 2);
        hud_1.Hud.addToHudLayer(container);
        this.container = container;
        this.background = scene.add.rectangle(0, 0, 600, 400, 0xffffff);
        this.background.setAlpha(0.5);
        this.container.add(this.background);
        const bpmText = scene.add.text(0, -100, `BPM: ${bpmChange.bpm}`);
        bpmText.setFontFamily('Arial');
        bpmText.setFontSize(20);
        bpmText.setColor('#FFFFFF');
        bpmText.setOrigin(0.5);
        bpmText.setStroke('#000000', 4);
        container.add(bpmText);
        const setBPM = new button_1.Button(scene, "set BPM", 200, -100, 150, 30, "button");
        setBPM.onClick = () => {
            const bpm = prompt("BPM") || "100";
            bpmChange.bpm = parseInt(bpm);
            bpmText.setText(`BPM: ${bpm}`);
        };
        container.add(setBPM.container);
        const closeButton = new button_1.Button(scene, "Close", 300, 200, 150, 50, "button");
        closeButton.onClick = () => {
            var _a;
            this.destroy();
            (_a = this.onClose) === null || _a === void 0 ? void 0 : _a.call(this);
        };
        container.add(closeButton.container);
        const deleteButton = new button_1.Button(scene, "Delete", 0, 100, 150, 50, "button");
        deleteButton.onClick = () => {
            var _a;
            const song = editorScene_1.EditorScene.Instance.song;
            song.bpms.splice(song.bpms.indexOf(bpmChange), 1);
            this.destroy();
            (_a = this.onClose) === null || _a === void 0 ? void 0 : _a.call(this);
        };
        container.add(deleteButton.container);
        const timeRange = new numberRange_1.NumberRange(scene, 200);
        this.timeRange = timeRange;
        this.timeRange.addBy = 0.5;
        this.timeRange.value = bpmChange.time;
        this.timeRange.onValueChange = () => {
            const diff = this.timeRange.value - bpmChange.time;
            const bpmChangeInterval = editorScene_1.EditorScene.Instance.bpmMeter.getBPMChangeInterval(bpmChange);
            bpmChange.time = this.timeRange.value;
            for (const note of editorScene_1.EditorScene.Instance.song.notes) {
                if (!(0, interval_1.isNumberBetweenInverval)(note.time, bpmChangeInterval))
                    continue;
                note.time += diff;
            }
        };
        container.add(timeRange.container);
        setInterval(() => {
            this.timeRange.update();
        }, 10);
    }
    destroy() {
        this.background.destroy();
        this.container.destroy();
    }
}
exports.BPMEditPanel = BPMEditPanel;
class BPMChangeItem {
    constructor(scene, bpmChange) {
        this._bpmChange = bpmChange;
        const container = scene.add.container();
        this.container = container;
        const rect = scene.add.rectangle(0, 0, 400, 30, 0xffffff);
        this.rectBackground = rect;
        container.add(rect);
        const text = scene.add.text(0, 0, `time: ${bpmChange.time.toFixed(1)} | BPM: ${bpmChange.bpm}`);
        text.setColor("#000000");
        text.setOrigin(0, 0.5);
        text.setPosition(-150, 0);
        container.add(text);
        const edit = new button_1.Button(scene, "Edit", -200, 0, 100, 30, "button");
        edit.onClick = () => {
            var _a;
            const editPanel = new BPMEditPanel(scene, bpmChange);
            this.destroy();
            (_a = this.onEdit) === null || _a === void 0 ? void 0 : _a.call(this);
        };
        container.add(edit.container);
    }
    update() {
        const time = gameScene_1.GameScene.Instance.soundPlayer.getCurrentSoundPosition();
        const currentBpmChange = editorScene_1.EditorScene.Instance.bpmMeter.getBPMChangeOfTime(time);
        const bgColor = currentBpmChange == this._bpmChange ? 0xff0000 : 0xffffff;
        this.rectBackground.fillColor = bgColor;
    }
    destroy() {
    }
}
exports.BPMChangeItem = BPMChangeItem;
class BPMOffsetsPanel {
    constructor(scene) {
        this.bpmChangeItems = [];
        const gameSize = gameface_1.Gameface.Instance.getGameSize();
        this.container = scene.add.container(0, 0);
        this.container.setPosition(gameSize.x / 2, gameSize.y / 2);
        mainScene_1.MainScene.Instance.layerHud.add(this.container);
        this.background = scene.add.rectangle(0, 0, 600, gameSize.y, 0xffffff);
        this.container.add(this.background);
        const addButton = new button_1.Button(scene, "Add bpm change", 300, 0, 150, 50, "button");
        addButton.onClick = () => {
            var _a;
            console.log("add");
            const time = gameScene_1.GameScene.Instance.soundPlayer.getCurrentSoundPosition();
            const bpmChange = {
                time: time,
                bpm: 120
            };
            editorScene_1.EditorScene.Instance.song.bpms.push(bpmChange);
            editorScene_1.EditorScene.Instance.song.bpms = editorScene_1.EditorScene.Instance.song.bpms.sort((a, b) => {
                return a.time - b.time;
            });
            this.destroy();
            (_a = this.onClose) === null || _a === void 0 ? void 0 : _a.call(this);
        };
        this.container.add(addButton.container);
        const closeButton = new button_1.Button(scene, "Close", 300, 50, 150, 50, "button");
        closeButton.onClick = () => {
            var _a;
            this.destroy();
            (_a = this.onClose) === null || _a === void 0 ? void 0 : _a.call(this);
        };
        this.container.add(closeButton.container);
        this.createBPMChanges();
    }
    createBPMChanges() {
        const scene = editorScene_1.EditorScene.Instance;
        const gameSize = gameface_1.Gameface.Instance.getGameSize();
        let y = -gameSize.y / 2 + 50;
        for (const bpmChange of editorScene_1.EditorScene.Instance.song.bpms) {
            const bpmChangeItem = new BPMChangeItem(scene, bpmChange);
            bpmChangeItem.container.setPosition(0, y);
            bpmChangeItem.onEdit = () => {
                this.destroy();
            };
            this.container.add(bpmChangeItem.container);
            this.bpmChangeItems.push(bpmChangeItem);
            y += 30;
        }
    }
    destroy() {
        this.background.destroy();
        this.container.destroy();
    }
    update() {
        for (const bpmChangeItem of this.bpmChangeItems) {
            bpmChangeItem.update();
        }
    }
}
exports.BPMOffsetsPanel = BPMOffsetsPanel;
