"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const debug_1 = require("../utils/debug/debug");
const gameface_1 = require("./gameface/gameface");
const gameScene_1 = require("./scenes/gameScene/gameScene");
const threeScene_1 = require("../utils/three/threeScene");
const editorScene_1 = require("./scenes/editor/editorScene");
const soundPlayer_1 = require("./scenes/gameScene/soundPlayer");
const mainScene_1 = require("./scenes/mainScene");
const gameSettings_1 = require("./constants/gameSettings");
const gameface = new gameface_1.Gameface();
function main() {
    return __awaiter(this, void 0, void 0, function* () {
        yield gameface.start();
    });
}
window.addEventListener('load', () => main());
window.game = {};
window.game.toggleFullscreen = () => { gameface_1.Gameface.Instance.toggleFullscreen(); };
window.game.showFPS = () => { mainScene_1.MainScene.Instance.toggleShowFPS(); };
window.game.setMoney = (amount) => {
    if (amount === undefined)
        throw "Invalid amount";
    gameface_1.Gameface.Instance.gameLogic.money = amount;
};
window.game.hardMode = () => {
    gameSettings_1.gameSettings.noteTimeToAchieve = 500;
    alert("Modo hard ativado!");
};
window.game.jumpToEnd = () => {
    gameScene_1.GameScene.Instance.soundPlayer.audio.currentTime = (gameScene_1.GameScene.Instance.soundPlayer.getFinishTime() / 1000) - 2;
};
window.game.forceFinish = (money) => {
    gameface.sendFinishGameWithCustomMoney(money);
};
if (gameSettings_1.gameSettings.exposeVars) {
    const w = window;
    w["gameface"] = gameface;
    w["GameScene"] = gameScene_1.GameScene;
    w["Debug"] = debug_1.Debug;
    w["ThreeScene"] = threeScene_1.ThreeScene;
    w["EditorScene"] = editorScene_1.EditorScene;
    w["SoundPlayer"] = soundPlayer_1.SoundPlayer;
}
