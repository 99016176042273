"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PhaserLoad = void 0;
const NineSlicePlugin = __importStar(require("phaser3-nineslice"));
const debug_1 = require("../debug/debug");
const config_1 = require("../../game/constants/config");
const phaser_extension_1 = require("@enable3d/phaser-extension");
var PreloadState;
(function (PreloadState) {
    PreloadState[PreloadState["NOT_LOADED"] = 0] = "NOT_LOADED";
    PreloadState[PreloadState["LOADING"] = 1] = "LOADING";
    PreloadState[PreloadState["COMPLETED"] = 2] = "COMPLETED";
})(PreloadState || (PreloadState = {}));
class PhaserLoad {
    static load(callback) {
        this._callback = callback;
        this.processLoad();
    }
    static loadAsync() {
        return __awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve) => {
                setTimeout(() => {
                    this.load((phaser) => {
                        resolve(phaser);
                    });
                }, 0);
            });
        });
    }
    static processLoad() {
        debug_1.Debug.log("PhaserLoad", "processLoad", this._loadState);
        if (this._loadState == PreloadState.NOT_LOADED) {
            this._loadState = PreloadState.LOADING;
            const cfg = config_1.config;
            cfg.plugins = {
                global: [
                    NineSlicePlugin.Plugin.DefaultCfg
                ]
            };
            (0, phaser_extension_1.enable3d)(() => {
                const game = new Phaser.Game(config_1.config);
                this._phaser = game;
                game.events.once('ready', () => {
                    this.processLoad();
                });
                return game;
            }).withPhysics('/assets/ammo/kripken');
            return;
        }
        this._loadState = PreloadState.COMPLETED;
        if (!this._phaser)
            throw "No phaser!";
        if (!this._callback)
            throw "No callback!";
        this._callback(this._phaser);
        this._callback = undefined;
    }
}
exports.PhaserLoad = PhaserLoad;
PhaserLoad._loadState = PreloadState.NOT_LOADED;
