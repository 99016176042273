"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MainScene = void 0;
const button_1 = require("../../utils/ui/button");
const utils_1 = require("../../utils/utils");
const gameSettings_1 = require("../constants/gameSettings");
const gameface_1 = require("../gameface/gameface");
const hud_1 = require("../hud/hud");
class MainScene extends Phaser.Scene {
    constructor() {
        super({});
        MainScene.Instance = this;
    }
    create() {
        return __awaiter(this, void 0, void 0, function* () {
            this.layerNormal = this.add.layer();
            this.layerNormal.setDepth(0);
            this.layerGround = this.add.layer();
            this.layerGround.setDepth(100);
            this.layerNotes = this.add.layer();
            this.layerNotes.setDepth(1000);
            this.layerBackground = this.add.layer();
            this.layerBackground.setDepth(5000);
            this.layerHud = this.add.layer();
            this.layerHud.setDepth(10000);
            this.fpsText = this.add.text(5, 5, '0 FPS').setFontFamily('Arial');
            this.fpsText.setFontSize(20);
            this.fpsText.setColor('#00ff00');
            this.fpsText.setOrigin(0);
            this.fpsText.setStroke('#000000', 4);
            this.layerHud.add(this.fpsText);
            this.fpsText.setVisible(gameSettings_1.gameSettings.showFPS);
            this.testText = this.add.text(5, 30, 'TEST').setFontFamily('Arial');
            this.testText.setFontSize(20);
            this.testText.setColor('#00ff00');
            this.testText.setOrigin(0);
            this.testText.setStroke('#000000', 4);
            this.testText.setVisible(false); //visible false
            const fullscreenButtonY = (0, utils_1.getIsMobile)() ? 300 : 120;
            const button = hud_1.Hud.addButton("Fullscreen", 30, fullscreenButtonY, 50, 50, "button");
            button.onClick = () => {
                gameface_1.Gameface.Instance.toggleFullscreen();
            };
            hud_1.Hud.addToHudLayer(button.container);
            button.container.setVisible(false);
            const gameSize = gameface_1.Gameface.Instance.getGameSize();
            const image = this.add.image(gameSize.x / 2, gameSize.y / 2, "background_load");
            this.loadBackground = image;
            if ((0, utils_1.getIsMobile)()) {
                image.setScale(1.5);
            }
            const howToPlay = this.add.image(gameSize.x / 2, gameSize.y / 2, "how_to_play");
            this.howToPlayImage = howToPlay;
            if ((0, utils_1.getIsMobile)()) {
                howToPlay.setScale(0.8);
            }
            else {
                howToPlay.setScale(0.6);
            }
        });
    }
    toggleShowFPS() {
        gameSettings_1.gameSettings.showFPS = !gameSettings_1.gameSettings.showFPS;
        this.setFPSVisible(gameSettings_1.gameSettings.showFPS);
    }
    setFPSVisible(visible) {
        this.fpsText.setVisible(visible);
    }
    update(time, delta) {
        if (gameSettings_1.gameSettings.showFPS)
            this.fpsText.setText(`${this.game.loop.actualFps.toFixed(2)} FPS`);
        /*
        this.testText.setText([
            'activePointer.isDown: ' + this.input.activePointer?.isDown,
            'pointer1.isDown: ' + this.input.pointer1?.isDown,
            'pointer2.isDown: ' + this.input.pointer2?.isDown,
            'pointer3.isDown: ' + this.input.pointer3?.isDown,
            'pointer4.isDown: ' + this.input.pointer4?.isDown
        ]);
        */
    }
    createPlayButton() {
        const gameSize = gameface_1.Gameface.Instance.getGameSize();
        const button = new button_1.Button(this, "", gameSize.x / 2, gameSize.y - 200, 450, 70, "play_button");
        if (!(0, utils_1.getIsMobile)()) {
            button.container.setPosition(button.container.x, gameSize.y - 50);
        }
        button.onClick = () => {
            var _a, _b, _c;
            button.destroy();
            (_a = this.loadBackground) === null || _a === void 0 ? void 0 : _a.destroy();
            this.loadBackground = undefined;
            (_b = this.howToPlayImage) === null || _b === void 0 ? void 0 : _b.destroy();
            this.howToPlayImage = undefined;
            (_c = this.onStart) === null || _c === void 0 ? void 0 : _c.call(this);
        };
    }
}
exports.MainScene = MainScene;
