"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Pads = void 0;
const baseObject_1 = require("../../utils/baseObject");
const threeScene_1 = require("../../utils/three/threeScene");
const pad_1 = require("./pad");
class Pads extends baseObject_1.BaseObject {
    constructor() {
        super(...arguments);
        this.padHeight = 0.2;
        this._pads = [];
    }
    get pads() { return this._pads; }
    ;
    get numOfPads() { return this._pads.length; }
    ;
    addPad(x, y, z, padData) {
        const scene = threeScene_1.ThreeScene.Instance;
        const box = scene.third.add.box({ width: 0.1, height: 0.1, depth: 0.1 });
        const object = threeScene_1.ThreeScene.addPhaser3DObject(box);
        object.name = "Pad " + this._pads.length;
        box.position.set(x, y, z);
        const pad = new pad_1.Pad(padData, object);
        this._pads.push(pad);
        return pad;
    }
    getPad(index) {
        const pad = this._pads[index];
        if (pad == undefined) {
            console.error("Could not find pad " + index);
            return undefined;
        }
        return pad;
    }
    update(delta) {
        for (const pad of this._pads) {
            pad.update(delta);
        }
    }
    getPadDragging() {
        for (const pad of this._pads) {
            if (pad.draggingNote)
                return pad;
        }
        return undefined;
    }
    isAnyPadDragging() {
        return this.getPadDragging() != undefined;
    }
}
exports.Pads = Pads;
