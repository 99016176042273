"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SoundPlayer = void 0;
const audioManager_1 = require("../../../utils/audioManager/audioManager");
const gameface_1 = require("../../gameface/gameface");
const gameScene_1 = require("./gameScene");
class SoundPlayer {
    constructor() {
        this.autoFinishWhenNoMoreNotes = true;
        this._startedTime = 0;
        this._currentSoundPosition = -3000;
        //private _hasSongStarted: boolean = false;
        this._running = false;
    }
    get song() { return this._song; }
    ;
    get audio() { return this._audio; }
    ;
    get startedTime() { return this._startedTime; }
    ;
    startSong(song) {
        this._song = song;
        this._startedTime = performance.now();
        this._running = true;
        this._audio = audioManager_1.AudioManager.playAudio(song.sound);
        this._audio.volume = 0.05;
        //this._audio.pause();
        //this._audio.play();
        this.recreateNotes();
    }
    pauseSound() {
        const audio = this.audio;
        audio.pause();
    }
    resumeSound() {
        const audio = this.audio;
        audio.play();
    }
    isRunning() {
        return this._running;
    }
    getCurrentSoundPosition() {
        return this._currentSoundPosition + SoundPlayer.audioTimeDelay;
    }
    getRealAudioCurrentTime() {
        if (!this._running)
            return 0;
        return this.audio.currentTime * 1000;
    }
    getAudioDuration() {
        return this.audio.duration * 1000;
    }
    recreateNotes() {
        this.destroyNotes();
        const gameLogic = gameface_1.Gameface.Instance.gameLogic;
        for (const noteData of gameLogic.notes) {
            const note = gameScene_1.GameScene.Instance.notes.spawnNote(noteData);
            const dragSize = gameScene_1.GameScene.Instance.notes.getDistanceFromMs(noteData.songNote.dragTime);
            note.setDragSize(dragSize);
        }
    }
    destroyNotes() {
        gameScene_1.GameScene.Instance.notes.destroyNotes();
    }
    update(delta) {
        var _a;
        if (!this._running)
            return;
        this._currentSoundPosition = this.getRealAudioCurrentTime();
        if (this.autoFinishWhenNoMoreNotes) {
            //finish game
            const time = this.getRealAudioCurrentTime();
            //console.log("time:", time);
            const finishTime = this.getFinishTime();
            //console.log("finishTime:", finishTime);
            const finishDelay = 1000;
            if (time >= (finishTime + finishDelay) || this._audio.ended) {
                (_a = this._audio) === null || _a === void 0 ? void 0 : _a.pause();
                console.log("finish");
                this._running = false;
                gameface_1.Gameface.Instance.onSongEnd();
            }
        }
    }
    getFinishTime() {
        const notes = gameScene_1.GameScene.Instance.notes;
        const lastNote = notes.getLastNote();
        let finishTime = lastNote.songNote.time + lastNote.songNote.dragTime;
        let soundFinishTime = this.getAudioDuration();
        if (soundFinishTime < finishTime)
            finishTime = soundFinishTime;
        if (gameface_1.Gameface.Instance.gameLogic.demoSongDuration != undefined)
            return gameface_1.Gameface.Instance.gameLogic.demoSongDuration;
        return finishTime;
    }
    getElapsedTime() {
        const now = performance.now();
        return now - this._startedTime;
    }
    crashGame() {
        this._audio = undefined;
    }
}
exports.SoundPlayer = SoundPlayer;
//public soundDelay: number = 2000; // ?
SoundPlayer.audioTimeDelay = 100; // delay to fix difference between Unity editor and the game
/*
    public getNextNote()
    {
        const time = this.getElapsedTime();

        //console.log(time);

        const song = this._song;

        if(!song) return;

        for(const note of song.notes)
        {
            const index = song.notes.indexOf(note);

            const nextNote = song.notes[index + 1];

            if(nextNote)
            {
                if(time > nextNote.time) continue;
            }

            //if(time < note.time) continue;

            return note;
        }

        return;
    }
*/ 
