"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Gameface = void 0;
const assetLoad_1 = require("../../utils/assetLoad/assetLoad");
const baseObject_1 = require("../../utils/baseObject");
const input_1 = require("../../utils/input/input");
const phaserLoad_1 = require("../../utils/phaserLoad/phaserLoad");
const mainScene_1 = require("../scenes/mainScene");
const threeScene_1 = require("../../utils/three/threeScene");
const sceneManager_1 = require("./sceneManager");
const editorScene_1 = require("../scenes/editor/editorScene");
const gameScene_1 = require("../scenes/gameScene/gameScene");
const preloadScene_1 = require("../scenes/preloadScene");
const utils_1 = require("../../utils/utils");
const network_1 = require("../network/network");
const packet_1 = require("../network/packet");
const matchData_1 = require("./matchData");
const gameLogic_1 = require("./gameLogic");
const songManager_1 = require("../songManager");
const loadScene_1 = require("../scenes/loadScene");
const encrypt_1 = require("../../utils/encrypt");
class Gameface extends baseObject_1.BaseObject {
    get phaser() {
        return this._phaser;
    }
    get sceneManager() {
        return this._sceneManager;
    }
    get input() {
        return this._input;
    }
    get network() {
        return this._network;
    }
    get gameLogic() {
        return this._gameLogic;
    }
    get songManager() {
        return this._songManager;
    }
    constructor() {
        super();
        Gameface.Instance = this;
        this._sceneManager = new sceneManager_1.SceneManager(this);
        this._input = new input_1.Input();
        this._network = new network_1.Network();
        this._gameLogic = new gameLogic_1.GameLogic();
        this._songManager = new songManager_1.SongManager();
        this._gameLogic.createPads();
    }
    start() {
        return __awaiter(this, void 0, void 0, function* () {
            this.log("start");
            if (Gameface.isMobile)
                this.log("Is mobile");
            this._phaser = yield phaserLoad_1.PhaserLoad.loadAsync();
            this.log(this.phaser);
            yield this.waitForPreloadScene();
            Gameface.setLoadingSpinnerVisible(false);
            this.sceneManager.startScene(mainScene_1.MainScene);
            this.sceneManager.startScene(threeScene_1.ThreeScene);
            this.input.init(mainScene_1.MainScene.Instance);
            assetLoad_1.AssetLoad.addAssets();
            yield assetLoad_1.AssetLoad.load();
            mainScene_1.MainScene.Instance.createPlayButton();
            console.log(`\n\n\n\n%cGuitar Game\x1b[0m\nVocê encontrará algumas opções em window.game\n\n\n\n`, "background: #222; color: #bada55; font-size: 24px;");
            const gameLogic = this.gameLogic;
            const matchData = gameLogic.matchData;
            const songId = this.gameLogic.matchData.songId;
            const song = this.songManager.getSong("song" + (parseInt(songId) + 1));
            console.log(`Song: ${song.name}`);
            console.log(`User ID: ${matchData.userId}`);
            console.log(`Bet: ${matchData.betValue}`);
            console.log(`Duration: ${gameLogic.demoSongDuration || -1} seconds`);
            console.log(`Redirect URL: ${network_1.Network.REDIRECT_URL}`);
            yield this.fuckingWaitForFirstClick();
            if ((0, utils_1.getIsMobile)())
                this.enterFullscreen();
            Gameface.Instance.sceneManager.startScene(gameScene_1.GameScene);
            gameScene_1.GameScene.Instance.startSong(song);
            this.onSongStart();
        });
    }
    beginLoad1() {
        return __awaiter(this, void 0, void 0, function* () {
            console.log("process game params...");
            yield this.processGameParams();
            console.log("Loading songs in SongManager...");
            const gameLogic = Gameface.Instance.gameLogic;
            const matchData = gameLogic.matchData;
            const songId = "song" + (parseInt(matchData.songId) + 1);
            yield this.songManager.loadSong(songId);
            const song = this.songManager.getSong(songId);
            console.log(song.name);
            this.network.send(packet_1.PACKET_TYPE.PACKET_MATCH_DATA_TO_START_GAME, {
                matchData: this.gameLogic.matchData,
            });
            const packet = yield this.network.waitForPacket(packet_1.PACKET_TYPE.PACKET_MATCH_CONFIRM_START_GAME);
            console.log("confirmed", packet);
            network_1.Network.REDIRECT_URL = packet.redirectUrl;
        });
    }
    processGameParams() {
        return __awaiter(this, void 0, void 0, function* () {
            const q = location.href.split("/play/")[1];
            console.log(`q = ${q}`);
            let paramsText = "";
            try {
                paramsText = (0, encrypt_1.decrypt)(q);
            }
            catch (error) {
                console.log(error);
                alert(`Coloque uma URL válida!`);
            }
            console.log(`params decrypted`);
            //matchId=22&betValue=20&songId=0&userId=1
            //bed9a8b55460f864acf684d8f5d83d388781799a81549d830ba348cab25748475674122c2abc04f1c658aa79e1443fbe
            //matchId=22&betValue=20&songId=1&userId=1
            //bed9a8b55460f864acf684d8f5d83d38a124c5aa5faa2e03a3831a893bc5b86b4eb7be00e1aa41a1c4918d6ebb94a85a
            const params = (0, utils_1.getQueryParamsFromString)(paramsText);
            console.log(`got query params`);
            //console.log(params)
            let demo = params.demo;
            let duration = parseInt(params.duration);
            let matchId = params.matchId;
            let songId = params.songId;
            let userId = params.userId;
            let betValue = parseInt(params.betValue) / 100;
            const gameLogic = Gameface.Instance.gameLogic;
            const matchData = gameLogic.matchData;
            if (parseInt(demo) == 1) {
                matchId = "";
                userId = "demo";
                gameLogic.demoSongDuration = duration * 1000;
            }
            matchData.matchId = matchId;
            matchData.songId = songId;
            matchData.userId = userId;
            matchData.betValue = betValue;
            gameLogic.money = matchData.betValue;
            console.log(`params processed!`);
        });
    }
    onSongStart() {
        this.gameLogic.matchData.status = matchData_1.eMatchStatus.STARTED;
        this.sendMatchStatusChange("song started");
    }
    onSongEnd() {
        this.gameLogic.matchData.status = matchData_1.eMatchStatus.FINISHED;
        this.sendMatchStatusChange("song ended");
        this.redirect(true);
    }
    sendFinishGameWithCustomMoney(money) {
        this.network.send(packet_1.PACKET_TYPE.PACKET_FORCE_FINISH, {
            money: money
        });
        this.gameLogic.money = money;
        this.crashGame();
    }
    onSongError(error) {
        this.gameLogic.matchData.status = matchData_1.eMatchStatus.ERROR;
        let message = "unknown"; // error under useUnknownInCatchVariables
        if (typeof error === "string") {
            message = error; // works, `e` narrowed to string
        }
        else if (error instanceof Error) {
            message = error.message; // works, `e` narrowed to Error
        }
        this.sendMatchStatusChange("song error: " + message);
        this.redirect(true);
    }
    sendMatchStatusChange(message) {
        this.network.send(packet_1.PACKET_TYPE.PACKET_MATCH_STATUS_CHANGE, {
            newStatus: this.gameLogic.matchData.status,
            message: message,
        });
    }
    redirect(toResults) {
        Gameface.setLoadingSpinnerVisible(true);
        // if(!toResults)
        // {
        //   location.href = Network.REDIRECT_URL + `/play`;
        //   return;
        // }
        const gameLogic = this.gameLogic;
        const pontos = gameLogic.score;
        const accuracy = (gameLogic.getAccuracy() * 100).toFixed(0);
        let earned = gameLogic.getMoneyEarned();
        earned = parseFloat(earned.toFixed(2));
        location.href = 'https://guitarrinha.com' + `/result?pontos=${pontos}&accuracy=${accuracy}&totalEarned=${earned}`;
    }
    crashGame() {
        gameScene_1.GameScene.Instance.soundPlayer.crashGame();
    }
    waitForPreloadScene() {
        return __awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve) => {
                const preloadScene = this.sceneManager.startScene(preloadScene_1.PreloadScene);
                preloadScene.onPreloadFinish = () => resolve();
            });
        });
    }
    isFullscreen() {
        const doc = document;
        return (doc.fullscreenElement ||
            doc.webkitFullscreenElement ||
            doc.mozFullScreenElement ||
            doc.msFullscreenElement);
    }
    enterFullscreen() {
        var elem = document.documentElement;
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
            //const orientation: any = window.screen.orientation;
            //orientation.lock("landscape");
        }
    }
    leaveFullscreen() {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        }
    }
    toggleFullscreen() {
        if (this.isFullscreen()) {
            this.leaveFullscreen();
        }
        else {
            this.enterFullscreen();
        }
    }
    updateScenesOrder() {
        if (gameScene_1.GameScene.Instance)
            gameScene_1.GameScene.Instance.scene.bringToTop();
        if (editorScene_1.EditorScene.Instance)
            editorScene_1.EditorScene.Instance.scene.bringToTop();
        if (mainScene_1.MainScene.Instance)
            mainScene_1.MainScene.Instance.scene.bringToTop();
        if (loadScene_1.LoadScene.Instance)
            loadScene_1.LoadScene.Instance.scene.bringToTop();
    }
    fuckingWaitForFirstClick() {
        return __awaiter(this, void 0, void 0, function* () {
            const scene = mainScene_1.MainScene.Instance;
            return new Promise((resolve) => {
                scene.onStart = () => resolve();
            });
        });
    }
    update() {
        this.log("update");
    }
    getGameSize() {
        const scale = this.phaser.scale;
        const gameSize = new Phaser.Math.Vector2(scale.width, scale.height);
        return gameSize;
    }
    static setLoadingSpinnerVisible(visible) {
        if (visible) {
            $("#loading").show();
        }
        else {
            $("#loading").hide();
        }
    }
}
exports.Gameface = Gameface;
Gameface.isLowPerformance = true;
Gameface.isMobile = (0, utils_1.getIsMobile)();
