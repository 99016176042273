"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PadHitText = void 0;
const graph_1 = require("../../utils/graph");
class PadHitText {
    constructor(scene) {
        this.positionYGraph = new graph_1.Graph();
        this.timeVisible = 0;
        const container = scene.add.container();
        this.container = container;
        const text = scene.add.text(0, 0, '+1');
        text.setFontFamily('Arial');
        text.setFontSize(40);
        text.setColor('#ffffff');
        text.setOrigin(0.5);
        text.setStroke('#000000', 8);
        container.add(text);
        this.text = text;
        this.positionYGraph.add(0, 100);
        this.positionYGraph.add(-100, 100);
        this.positionYGraph.add(-80, 0);
    }
    update(delta) {
        this.timeVisible -= delta;
        this.positionYGraph.changeCurrentTimeBy(delta);
        this.text.setPosition(0, this.positionYGraph.getValue());
        this.text.setVisible(this.timeVisible > 0);
    }
    show() {
        this.positionYGraph.currentTime = 0;
        this.timeVisible = 300;
    }
}
exports.PadHitText = PadHitText;
