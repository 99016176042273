"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AudioManager = void 0;
const mainScene_1 = require("../../game/scenes/mainScene");
const assetLoad_1 = require("../assetLoad/assetLoad");
class AudioManager {
    static get sound() {
        const w = window;
        return w["createjs"].Sound;
    }
    ;
    static addAudio(key, url) {
        console.log("add audio", url);
        const asset = {
            key: key,
            url: url,
            audio: undefined,
            loadState: assetLoad_1.LoadState.LOADING
        };
        this.assets.set(key, asset);
    }
    static playAudio(key) {
        return this.playAudioWithVolume(key, 1.0);
    }
    static playAudioPhaser(key) {
        this.playAudioPhaserWithVolume(key, 1.0);
    }
    static playAudioPhaserWithVolume(key, volume) {
        mainScene_1.MainScene.Instance.sound.play(key, { volume: volume });
    }
    static playAudioWithVolume(key, volume) {
        const asset = this.assets.get(key);
        if (!asset)
            throw "Asset " + key + " not found";
        const audio = asset.audio;
        if (!audio)
            throw "Audio not found";
        audio.volume = volume;
        audio.play();
        return audio;
    }
    static loadAllAudios() {
        return __awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve) => {
                let notLoaded = this.getNotLoadedCount();
                for (const asset of this.assets.values()) {
                    console.log(`Loading html audio ${asset.url}`);
                    const audio = new Audio(`/assets/${asset.url}`);
                    //audio.preload = 'auto';
                    asset.audio = audio;
                    const self = this;
                    function markAsLoaded() {
                        if (asset.loadState == assetLoad_1.LoadState.LOADED) {
                            console.error("O audio já foi carregado, mas foi marcado como carregado novamente!");
                            return;
                        }
                        asset.loadState = assetLoad_1.LoadState.LOADED;
                        console.log(`Áudio HTML '${asset.url}' carregado!`);
                        notLoaded = self.getNotLoadedCount();
                        if (notLoaded == 0) {
                            resolve();
                        }
                    }
                    audio.addEventListener('progress', () => {
                        console.log('Carregando áudio:', audio.readyState);
                        markAsLoaded();
                        if (audio.readyState >= 2) {
                            console.log('Áudio pronto para reprodução: readyState = ' + audio.readyState);
                            markAsLoaded();
                        }
                    });
                    audio.addEventListener('canplaythrough', () => {
                        console.log('Áudio pronto para reprodução: canplaythrough');
                        markAsLoaded();
                    }, false);
                }
            });
        });
    }
    static getNotLoadedCount() {
        let i = 0;
        for (const asset of this.assets.values()) {
            if (asset.loadState == assetLoad_1.LoadState.LOADING)
                i++;
        }
        return i;
    }
}
exports.AudioManager = AudioManager;
AudioManager.assets = new Map();
