"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseObject = void 0;
const debug_1 = require("./debug/debug");
class BaseObject {
    log(...args) {
        const allArgs = [`${this.constructor.name}`].concat(args);
        debug_1.Debug.log.apply(this, allArgs);
    }
}
exports.BaseObject = BaseObject;
