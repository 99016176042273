"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GameScene = void 0;
const notes_1 = require("../../notes/notes");
const pads_1 = require("../../pads/pads");
const gameface_1 = require("../../gameface/gameface");
const soundPlayer_1 = require("./soundPlayer");
const ground_1 = require("./ground");
const threeScene_1 = require("../../../utils/three/threeScene");
const mainScene_1 = require("../mainScene");
const hud_1 = require("../../hud/hud");
const hitAccuracy_1 = require("./hitAccuracy");
const guitarHud_1 = require("./guitarHud");
const infoText_1 = require("./infoText");
const audioManager_1 = require("../../../utils/audioManager/audioManager");
const gameLogic_1 = require("../../gameface/gameLogic");
const utils_1 = require("../../../utils/utils");
const gameSettings_1 = require("../../constants/gameSettings");
class GameScene extends Phaser.Scene {
    get betValue() { return gameface_1.Gameface.Instance.gameLogic.matchData.betValue; }
    ;
    get money() { return gameface_1.Gameface.Instance.gameLogic.money; }
    ;
    get score() { return gameface_1.Gameface.Instance.gameLogic.score; }
    ;
    get combo() { return gameface_1.Gameface.Instance.gameLogic.combo; }
    ;
    get accumulatedMoney() { return gameface_1.Gameface.Instance.gameLogic.accumulatedMoney; }
    ;
    get soundPlayer() { return this._soundPlayer; }
    get notes() { return this._notes; }
    get pads() { return this._pads; }
    get hitAccuracy() { return this._hitAccuracy; }
    get infoText() { return this._infoText; }
    //public get gameProgressBar() { return this._gameProgressBar; }
    get guitarHud() { return this._guitarHud; }
    constructor() {
        super({});
        /*
        Events:
        pad_begin_drag
        pad_end_drag
    
        OBS: not working for some fucking reason
        */
        this.events = new Phaser.Events.EventEmitter();
        //public canvas?: Phaser.Textures.CanvasTexture;
        this.padKeys = ["A", "S", "D", "F", "G"];
        this.padColors = [0x00ff00, 0xff0000, 0xffff00, 0x0094FF, 0xFF8A3D];
        this.ground = new ground_1.Ground();
        this._soundPlayer = new soundPlayer_1.SoundPlayer();
        this._notes = new notes_1.Notes();
        this._pads = new pads_1.Pads();
        this._hitAccuracy = new hitAccuracy_1.HitAccuracy();
        this._infoText = new infoText_1.InfoText();
        //this._gameProgressBar = new GameProgressBar();
        this._guitarHud = new guitarHud_1.GuitarHud();
        GameScene.Instance = this;
        this.setupEvents();
    }
    setupEvents() {
        this.events.on("pad_begin_drag", () => {
        });
        const gameLogic = gameface_1.Gameface.Instance.gameLogic;
        gameLogic.events.on(gameLogic_1.eGameLogicEvents.EVENT_NOTE_HIT, (noteData, hitType) => {
            const note = this.notes.getNoteByNoteData(noteData);
            if (!note) {
                throw "GameScene: note not found wtf";
            }
            this.onNoteHit(note, hitType);
        });
        gameLogic.events.on(gameLogic_1.eGameLogicEvents.EVENT_BREAK_COMBO, () => {
            this.breakCombo();
        });
        gameLogic.events.on(gameLogic_1.eGameLogicEvents.EVENT_COMBO_REWARD, () => {
            this.infoText.showRandomHitMessage();
        });
        gameLogic.events.on(gameLogic_1.eGameLogicEvents.EVENT_PAD_BEGIN_DRAG, (padIndex, noteData) => {
            const pad = this.pads.getPad(padIndex);
            const note = this.notes.getNoteByNoteData(noteData);
            pad.startDrag(note);
        });
        gameLogic.events.on(gameLogic_1.eGameLogicEvents.EVENT_PAD_END_DRAG, (padIndex) => {
            const pad = this.pads.getPad(padIndex);
            pad.stopDrag();
        });
    }
    setupAnims() {
        this.anims.create({
            key: "pad_color_idle",
            frameRate: 4,
            frames: this.anims.generateFrameNames("pad_sheet", {
                prefix: "pad_color",
                suffix: ".png",
                start: 1,
                end: 1
            }),
            repeat: -1
        });
        this.anims.create({
            key: "pad_color_raise",
            frameRate: 25,
            frames: this.anims.generateFrameNames("pad_sheet", {
                prefix: "pad_color",
                suffix: ".png",
                start: 2,
                end: 3
            }),
            repeat: 0
        });
        this.anims.create({
            key: "note_color_idle",
            frameRate: 12,
            frames: this.anims.generateFrameNames("note_sheet", {
                prefix: "note_color",
                suffix: ".png",
                start: 1,
                end: 4
            }),
            repeat: -1
        });
    }
    create() {
        return __awaiter(this, void 0, void 0, function* () {
            this.setupAnims();
            this.ground.create();
            this.createBackground();
            this.createPads();
            //top right container
            const gameSize = gameface_1.Gameface.Instance.getGameSize();
            this.topRightContainer = this.add.container();
            this.topRightContainer.setPosition(gameSize.x, 0);
            hud_1.Hud.addToHudLayer(this.topRightContainer);
            this.guitarHud.create();
            this.hitAccuracy.create(this);
            this.infoText.create(this);
        });
    }
    createBackground() {
        //background
        const gameSize = gameface_1.Gameface.Instance.getGameSize();
        let x = 1280 / 900;
        let y = 720 / 600;
        //reset
        x = 1;
        y = 1;
        const backgroundId = 1;
        const backgroundTextureKey = `background${backgroundId}` + ((0, utils_1.getIsMobile)() ? '_mobile' : '');
        const background = this.add.image(0, 0, backgroundTextureKey);
        background.setOrigin(0.5);
        background.setPosition(gameSize.x / 2, gameSize.y / 2);
        background.setScale(x, y);
        mainScene_1.MainScene.Instance.layerBackground.add(background);
        const maskTextureKey = (0, utils_1.getIsMobile)() ? `mask_mobile` : `mask`;
        const shape = this.add.image(0, 0, maskTextureKey).setVisible(false);
        shape.setOrigin(0.5);
        shape.setPosition(gameSize.x / 2, gameSize.y / 2);
        shape.setScale(x, y);
        var mask = this.add.bitmapMask(shape);
        background.setMask(mask);
    }
    createPads() {
        //add pads
        const distance = 0.5;
        const numOfPads = gameface_1.Gameface.Instance.gameLogic.pads.length;
        let i = 0;
        for (const padData of gameface_1.Gameface.Instance.gameLogic.pads) {
            const totalDistance = (numOfPads - 1) * distance;
            const x = i * distance - totalDistance / 2;
            const z = this.ground.plankSize / 2;
            const pad = this.pads.addPad(x, this.pads.padHeight, z, padData);
            pad.setKey(this.padKeys[i]);
            pad.color = this.padColors[i];
            i++;
        }
    }
    startSong(song) {
        const gameLogic = gameface_1.Gameface.Instance.gameLogic;
        gameLogic.song = song;
        gameLogic.createNotes();
        GameScene.Instance.soundPlayer.startSong(song);
        this.guitarHud.createSongDetails();
    }
    startMultiplayerSong(song) {
        this.infoText.setText("waiting for opponent...", -1);
        setTimeout(() => {
            this.infoText.setText("Start!", 1000);
            //this.guitarHud.createSecondUser();
            setTimeout(() => {
                GameScene.Instance.soundPlayer.startSong(song);
            }, 1000);
        }, 3000);
    }
    update(time, delta) {
        //ThreeScene.Instance.third.camera.position.x += 0.01
        //ThreeScene.Instance.third.camera.lookAt(0, 0, 3)
        try {
            this.updateGame(delta);
        }
        catch (error) {
            gameface_1.Gameface.Instance.onSongError(error);
            throw error;
        }
    }
    updateGame(delta) {
        gameface_1.Gameface.Instance.gameLogic.songTime = this.soundPlayer.getCurrentSoundPosition();
        this.soundPlayer.update(delta);
        this.ground.update();
        this.notes.update(delta);
        this.pads.update(delta);
        this.hitAccuracy.update(delta);
        this.infoText.update(delta);
        //this.gameProgressBar.update(delta);
        this._guitarHud.update(delta);
    }
    onNoteHit(note, hitType) {
        if (gameSettings_1.gameSettings.playHitSound)
            audioManager_1.AudioManager.playAudioPhaserWithVolume("osu_hitsound", 0.1);
        GameScene.Instance.hitAccuracy.setHitType(hitType);
        const notesToReward = gameSettings_1.gameSettings.comboAward;
        const nextReward = (Math.floor(this.combo / notesToReward) * notesToReward) + notesToReward;
        GameScene.Instance.hitAccuracy.setComboText(`${GameScene.Instance.combo} / ${nextReward}`);
    }
    breakCombo() {
        GameScene.Instance.hitAccuracy.visibleTime = 0;
        GameScene.Instance.hitAccuracy.setComboText(`0`);
        this.infoText.showRandomMissMessage();
        this.guitarHud.moneyText.shake();
    }
    static saveImage() {
        const scene = this.Instance;
        //const canvas = scene.add.renderTexture(0, 0, 500, 500);
        threeScene_1.ThreeScene.Instance.third.renderer.render(threeScene_1.ThreeScene.Instance.third.scene, threeScene_1.ThreeScene.Instance.third.camera);
        const canvas = threeScene_1.ThreeScene.Instance.third.renderer.domElement;
        const url = canvas.toDataURL();
        console.log(url);
        const gl = canvas.getContext('webgl');
        if (gl) {
            //gl1.clearColor(1, 0, 0, 1);
            //gl1.clear(gl1.COLOR_BUFFER_BIT);
            const w = gl.canvas.width;
            const h = gl.canvas.width;
            var data = new Uint8Array(w * h * 4);
            gl.readPixels(0, 0, w, h, gl.RGBA, gl.UNSIGNED_BYTE, data);
            console.log(data);
        }
    }
}
exports.GameScene = GameScene;
