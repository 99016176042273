"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProgressBar = void 0;
class ProgressBar {
    constructor(scene, width, height) {
        this.container = scene.add.container(0, 0);
        const margin = 3;
        const background = scene.add.rectangle(0, 0, width, height, 0x000000);
        this.background = background;
        this.container.add(background);
        const backgroundRest = scene.add.rectangle(0, 0, width - (margin * 2), height - (margin * 2), 0xff0000);
        this.backgroundRest = backgroundRest;
        this.container.add(backgroundRest);
        const backgroundProgress = scene.add.rectangle(0, 0, width - (margin * 2), height - (margin * 2), 0x00ff00);
        this.backgroundProgress = backgroundProgress;
        backgroundProgress.setPosition(-width / 2 + margin, -height / 2 + margin);
        backgroundProgress.setOrigin(0, 0);
        this.container.add(backgroundProgress);
    }
    setProgressColor(color) {
        this.backgroundProgress.fillColor = color;
    }
    setRestColor(color) {
        this.backgroundRest.fillColor = color;
    }
    setProgress(progress) {
        this.backgroundProgress.setScale(progress, 1);
    }
}
exports.ProgressBar = ProgressBar;
