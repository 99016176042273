"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SceneManager = void 0;
const baseObject_1 = require("../../utils/baseObject");
class SceneManager extends baseObject_1.BaseObject {
    get phaser() { return this._gameface.phaser; }
    ;
    constructor(gameface) {
        super();
        this._gameface = gameface;
    }
    startScene(scene) {
        const phaser = this.phaser;
        const key = scene.name;
        if (this.hasSceneStarted(scene)) {
            this.removeScene(scene);
        }
        const s = phaser.scene.add(key, scene, true);
        this.log("start scene: " + key);
        this._gameface.updateScenesOrder();
        if (s)
            return s;
        return s;
    }
    removeScene(scene) {
        const phaser = this.phaser;
        const key = scene.name;
        this.log("removeScene", key);
        if (this.hasSceneStarted(scene)) {
            const s = phaser.scene.keys[key];
            s.scene.remove();
        }
    }
    hasSceneStarted(scene) {
        const phaser = this.phaser;
        return phaser.scene.keys[scene.name];
    }
}
exports.SceneManager = SceneManager;
