"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.gameSettings = void 0;
const utils_1 = require("../../utils/utils");
exports.gameSettings = {
    playHitSound: (0, utils_1.getIsMobile)() ? false : false,
    comboAward: 20,
    showFPS: true,
    noteTimeToAchieve: 1000,
    currency: "GC$",
    exposeVars: true
};
