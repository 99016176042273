"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Phaser3DObject = void 0;
const debugText_1 = require("../debug/debugText");
const threeScene_1 = require("./threeScene");
class Phaser3DObject {
    constructor(threeObject) {
        this.name = "Object";
        this.object = threeObject;
        this.debugText = new debugText_1.DebugText("Object");
    }
    update() {
        this.debugText.setLine("title", this.name);
        //this.debugText.setLine("scale", `Scale:  ${this.getScale().toFixed(2)}`);
        this.debugText.set3DPosition(this.object.position);
        this.debugText.update();
    }
    destroy() {
        this.debugText.destroy();
        threeScene_1.ThreeScene.Instance.third.scene.remove(this.object);
    }
    getScale() {
        const left = this.object.position.clone();
        left.x -= 0.5;
        const right = this.object.position.clone();
        right.x += 0.5;
        const s1 = threeScene_1.ThreeScene.projectToScreen(left);
        const s2 = threeScene_1.ThreeScene.projectToScreen(right);
        return (s2.x - s1.x) * 0.01;
    }
    setInvisible() {
        const mat = this.object.material;
        this.object.castShadow = false;
        mat.transparent = true;
        mat.opacity = 0.0;
    }
}
exports.Phaser3DObject = Phaser3DObject;
