"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddNote = exports.PadSelector = void 0;
const button_1 = require("../../../utils/ui/button");
const gameface_1 = require("../../gameface/gameface");
const gameScene_1 = require("../gameScene/gameScene");
const mainScene_1 = require("../mainScene");
const editorScene_1 = require("./editorScene");
class PadSelector {
    constructor(scene) {
        this.selected = false;
        const container = scene.add.container(0, 0);
        this.container = container;
        const image = scene.add.image(0, 0, "note");
        this.image = image;
        container.add(image);
        const self = this;
        image.setInteractive(new Phaser.Geom.Rectangle(0, 0, 50, 50), Phaser.Geom.Rectangle.Contains);
        image.on('pointerdown', function () {
            self.selected = !self.selected;
            self.updateImage();
        }, this);
        this.updateImage();
    }
    updateImage() {
        this.image.tint = this.selected ? 0xffffff : 0x777777;
    }
}
exports.PadSelector = PadSelector;
/*
TODO: destroy padselector
*/
class AddNote {
    constructor(scene) {
        this.padSelectors = [];
        const gameSize = gameface_1.Gameface.Instance.getGameSize();
        this.container = scene.add.container(0, 0);
        this.container.setPosition(gameSize.x / 2, gameSize.y / 2);
        mainScene_1.MainScene.Instance.layerHud.add(this.container);
        this.background = scene.add.rectangle(0, 0, 400, 400, 0xffffff);
        this.container.add(this.background);
        const numPads = 5;
        for (let i = 0; i < numPads; i++) {
            const padSelector = new PadSelector(scene);
            this.container.add(padSelector.container);
            padSelector.container.setPosition((i - (numPads - 1) / 2) * 60, 0);
            this.padSelectors.push(padSelector);
        }
        this.addButton = new button_1.Button(scene, "Add", 0, 200, 80, 50, "button");
        this.addButton.onClick = () => {
            var _a;
            console.log("add");
            const song = editorScene_1.EditorScene.Instance.song;
            const time = gameScene_1.GameScene.Instance.soundPlayer.getCurrentSoundPosition();
            const pads = [];
            for (const pad of this.padSelectors) {
                if (pad.selected) {
                    const index = this.padSelectors.indexOf(pad);
                    pads.push(index);
                }
            }
            if (pads.length > 0) {
                const songNote = {
                    time: time,
                    pads: pads,
                    dragTime: 0
                };
                song.notes.push(songNote);
                gameScene_1.GameScene.Instance.soundPlayer.recreateNotes();
            }
            this.destroy();
            (_a = this.onClose) === null || _a === void 0 ? void 0 : _a.call(this);
        };
        this.container.add(this.addButton.container);
    }
    destroy() {
        this.background.destroy();
        this.container.destroy();
        this.addButton.destroy();
    }
}
exports.AddNote = AddNote;
