"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Pad = void 0;
const baseObject_1 = require("../../utils/baseObject");
const input_1 = require("../../utils/input/input");
const threeScene_1 = require("../../utils/three/threeScene");
const gameface_1 = require("../gameface/gameface");
const gameLogic_1 = require("../gameface/gameLogic");
const packet_1 = require("../network/packet");
const editorScene_1 = require("../scenes/editor/editorScene");
const gameScene_1 = require("../scenes/gameScene/gameScene");
const mainScene_1 = require("../scenes/mainScene");
const padHitText_1 = require("./padHitText");
class Pad extends baseObject_1.BaseObject {
    constructor(padData, object) {
        super();
        this.position = new Phaser.Math.Vector2();
        this.startedDragAtTime = 0;
        this._active = false;
        this._lastTimeAwardedScoreByDragging = 0;
        this.color = 0x0000ff;
        this.pointerIdPressingThisPad = 0;
        this.padData = padData;
        this.object = object;
        //this.object.debugText.createDebugText();
        this.object.setInvisible();
        const scene = gameScene_1.GameScene.Instance;
        this.container = scene.add.container(50, 50);
        mainScene_1.MainScene.Instance.layerNotes.add(this.container);
        this.sprite = scene.add.sprite(0, 0, 'pad_sheet', 'pad_color1.png');
        this.sprite.setScale(2.2);
        this.container.add(this.sprite);
        this.spriteColor = scene.add.sprite(0, 0, 'pad_sheet', 'pad_color2.png');
        this.spriteColor.setScale(2.2);
        this.container.add(this.spriteColor);
        this.padHitText = new padHitText_1.PadHitText(scene);
        this.container.add(this.padHitText.container);
        const pad = this;
        input_1.Input.events.on("pointerdown", (event, pointerId) => {
            //console.log("poniter down")
            const mousePosition = input_1.Input.mousePosition;
            const position = this.position;
            const distance = position.distance(mousePosition);
            //console.log(mousePosition, distance)
            if (distance < 70) {
                //console.log(distance)
                this.pointerIdPressingThisPad = pointerId;
                this.activatePad();
            }
        });
        input_1.Input.events.on("pointerup", (event, pointerId) => {
            if (this.pointerIdPressingThisPad != pointerId)
                return;
            this.deactivatePad();
        });
    }
    activatePad() {
        var _a;
        this._active = true;
        (_a = this.spriteColor) === null || _a === void 0 ? void 0 : _a.anims.play('pad_color_raise');
        const index = this.getIndex();
        const padDownData = gameface_1.Gameface.Instance.gameLogic.processPadDown(index);
        gameface_1.Gameface.Instance.network.send(packet_1.PACKET_TYPE.PACKET_PAD_DOWN_OR_UP, {
            down: true,
            index: this.getIndex(),
            time: gameface_1.Gameface.Instance.gameLogic.songTime
        });
        if (padDownData != undefined) {
            const hitType = padDownData.hitType;
            const noteData = padDownData.note;
            const countAsHit = hitType != gameLogic_1.eNoteHitGood.HIT_NOT_ON_TIME;
            if (countAsHit) {
                //GameScene.Instance.onNoteHit(note, hitType, false);
                if (gameface_1.Gameface.Instance.sceneManager.hasSceneStarted(editorScene_1.EditorScene))
                    return;
                const note = gameScene_1.GameScene.Instance.notes.getNoteByNoteData(noteData);
                this.hitNote(note);
            }
        }
        gameScene_1.GameScene.Instance.events.emit("pad_down", this);
    }
    hitNote(note) {
        this.padHitText.show();
        if (note.songNote.dragTime > 0) {
            //moved to gamelogic
            //this.startDrag(note);
        }
    }
    startDrag(note) {
        note.startBeeingDragged(this);
        this.draggingNote = note;
        this.startedDragAtTime = gameScene_1.GameScene.Instance.soundPlayer.getCurrentSoundPosition();
        this._lastTimeAwardedScoreByDragging = this.startedDragAtTime;
        gameScene_1.GameScene.Instance.events.emit("pad_begin_drag", this, note);
    }
    stopDrag() {
        const note = this.draggingNote;
        if (!note)
            return;
        note.stopBeeingDragged();
        this.draggingNote = undefined;
        this.startedDragAtTime = 0;
        //GameScene.Instance.onNoteHit(note, hitType, true);
        //GameScene.Instance.events.emit("pad_end_drag", this, note);
    }
    deactivatePad() {
        var _a;
        if (!this._active)
            return;
        this._active = false;
        (_a = this.spriteColor) === null || _a === void 0 ? void 0 : _a.anims.playReverse('pad_color_raise');
        gameface_1.Gameface.Instance.gameLogic.processPadUp(this.getIndex());
        gameface_1.Gameface.Instance.network.send(packet_1.PACKET_TYPE.PACKET_PAD_DOWN_OR_UP, {
            down: false,
            index: this.getIndex(),
            time: gameface_1.Gameface.Instance.gameLogic.songTime
        });
        //this.stopDrag();
    }
    getPosition() {
        return this.object.object.position;
    }
    getIndex() {
        const index = gameScene_1.GameScene.Instance.pads.pads.indexOf(this);
        return index;
    }
    setKey(key) {
        const keyBoard = gameface_1.Gameface.Instance.input.input.keyboard;
        if (!keyBoard)
            throw "Keyboard is null";
        const keyObject = keyBoard.addKey(key);
        const pad = this;
        keyObject.on('down', function (event) {
            pad.activatePad();
        });
        keyObject.on('up', function (event) {
            pad.deactivatePad();
        });
    }
    update(delta) {
        this.padHitText.update(delta);
        const active = this._active;
        // set position
        const screenPosition = threeScene_1.ThreeScene.projectToScreen(this.object.object.position);
        this.position.set(screenPosition.x, screenPosition.y);
        this.container.setPosition(this.position.x, this.position.y);
        //set colors
        if (this.spriteColor) {
            this.spriteColor.tint = active ? this.color : 0x777777;
        }
        if (this.sprite) {
            this.sprite.tint = this.color;
        }
        //award score
        if (this.draggingNote) {
            /*
            const now = GameScene.Instance.soundPlayer.getCurrentSoundPosition();
            if(now - this._lastTimeAwardedScoreByDragging >= 500)
            {
                this._lastTimeAwardedScoreByDragging = now;

                GameScene.Instance.score += 100;
            }
            */
        }
    }
}
exports.Pad = Pad;
