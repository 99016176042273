"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HitAccuracy = void 0;
const baseObject_1 = require("../../../utils/baseObject");
const graph_1 = require("../../../utils/graph");
const utils_1 = require("../../../utils/utils");
const gameface_1 = require("../../gameface/gameface");
const gameLogic_1 = require("../../gameface/gameLogic");
const hud_1 = require("../../hud/hud");
class HitAccuracy extends baseObject_1.BaseObject {
    constructor() {
        super();
        this.scaleGraph = new graph_1.Graph();
        this.visibleTime = 0;
        this.comboVisible = false;
        this.accuracyInfo = new Map([
            [gameLogic_1.eNoteHitGood.HIT_PERFECT, { text: "PERFECT", color: 0x9000FF }],
            [gameLogic_1.eNoteHitGood.HIT_GOOD, { text: "GOOD", color: 0x74BF13 }],
            [gameLogic_1.eNoteHitGood.HIT_OK, { text: "OK", color: 0x808080 }],
            [gameLogic_1.eNoteHitGood.HIT_BAD, { text: "BAD", color: 0X808080 }],
            [gameLogic_1.eNoteHitGood.HIT_NOT_ON_TIME, { text: "OFF-TIME", color: 0X808080 }],
        ]);
        this.scaleGraph.add(0.5, 100);
        this.scaleGraph.add(1.3, 100);
        this.scaleGraph.add(1.0, 0);
    }
    create(scene) {
        const gameSize = gameface_1.Gameface.Instance.getGameSize();
        const container = scene.add.container();
        if ((0, utils_1.getIsMobile)()) {
            container.setPosition(gameSize.x / 2, gameSize.y / 2 + 100);
        }
        else {
            container.setPosition(gameSize.x / 2, 300);
        }
        hud_1.Hud.addToHudLayer(container);
        const text = scene.add.text(0, 0, 'HIT_ACCURACY').setFontFamily('Arial');
        text.setFontSize(40);
        text.setColor('#ffffff');
        text.setOrigin(0.5);
        text.setStroke('#000000', 8);
        container.add(text);
        this.text = text;
        const comboText = scene.add.text(0, 50, 'SCORE').setFontFamily('Arial');
        comboText.setFontSize(40);
        comboText.setColor('#ffffff');
        comboText.setOrigin(0.5);
        comboText.setStroke('#000000', 8);
        container.add(comboText);
        this.comboText = comboText;
        window.game.hitAccuracy = this;
    }
    update(delta) {
        this.scaleGraph.changeCurrentTimeBy(delta);
        if (this.visibleTime > 0) {
            this.visibleTime -= delta;
            if (this.visibleTime < 0)
                this.visibleTime = 0;
        }
        this.text.setScale(this.scaleGraph.getValue());
        this.text.setVisible(this.visibleTime > 0);
        this.comboText.setScale(this.scaleGraph.getValue());
        this.comboText.setVisible(this.comboVisible);
    }
    setHitType(hitType) {
        const info = this.accuracyInfo.get(hitType);
        if (!info)
            return;
        this.setText(info.text);
        this.setColor(info.color);
    }
    setText(str) {
        this.visibleTime = 1000;
        this.text.setText(str);
        this.scaleGraph.currentTime = 0;
    }
    setColor(color) {
        const hexString = color.toString(16);
        this.text.setColor(`#${hexString}`);
    }
    setComboText(str) {
        this.comboText.setText(str);
        this.scaleGraph.currentTime = 0;
        this.comboVisible = true;
    }
}
exports.HitAccuracy = HitAccuracy;
