"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoadScene = void 0;
const assetLoad_1 = require("../../utils/assetLoad/assetLoad");
const audioManager_1 = require("../../utils/audioManager/audioManager");
const progressBar_1 = require("../../utils/ui/progressBar");
const gameface_1 = require("../gameface/gameface");
class LoadScene extends Phaser.Scene {
    constructor() {
        super({});
        this._loadAssets = [];
        LoadScene.Instance = this;
    }
    create() {
        return __awaiter(this, void 0, void 0, function* () {
            const self = this;
            const load = this.load;
            load.setPath(assetLoad_1.AssetLoad.getAssetsUrl());
            load.on('filecomplete', function (key, type, data) {
                const asset = assetLoad_1.AssetLoad.getAssetByKey(key);
                if (!asset)
                    return;
                asset.loadState = assetLoad_1.LoadState.LOADED;
                console.log("filecomplete", asset.path);
                self.printLoadState();
            });
            const gamesize = gameface_1.Gameface.Instance.getGameSize();
            //const bg = this.add.rectangle(0, 0, gamesize.x, gamesize.y, 0x000000);
            //bg.setOrigin(0, 0);
            const progressBar = new progressBar_1.ProgressBar(this, gamesize.x - 50, 30);
            progressBar.setProgress(0);
            progressBar.setProgressColor(0xFFAC00);
            progressBar.setRestColor(0xffffff);
            progressBar.container.setPosition(gamesize.x / 2, gamesize.y - 40);
            this.progressBar = progressBar;
            const mobileText = this.add.text(0, 0, gameface_1.Gameface.isMobile ? "Mobile" : "Desktop");
            mobileText.setFontFamily('Arial');
            mobileText.setOrigin(0);
        });
    }
    update(time, delta) {
        let totalAssets = this.getNumberOfAssets();
        let loadedAssets = this.getNumberOfLoadedAssets();
        this.progressBar.setProgress(loadedAssets / totalAssets);
    }
    getNumberOfLoadedAssets() {
        let loadedAssets = 0;
        for (const loadAsset of this._loadAssets) {
            if (loadAsset.asset.loadState == assetLoad_1.LoadState.LOADED)
                loadedAssets++;
        }
        return loadedAssets;
    }
    getNumberOfAssets() {
        return this._loadAssets.length;
    }
    loadAsset(asset) {
        console.log(`[LoadScene] add loadAsset image '${asset.key}'`);
        const loadAsset = {
            text: `Loading image ${asset.key}`,
            asset: asset
        };
        this._loadAssets.push(loadAsset);
    }
    printLoadState() {
        let totalAssets = this.getNumberOfAssets();
        let loadedAssets = this.getNumberOfLoadedAssets();
        console.log(`Loaded assets: ${loadedAssets} / ${totalAssets}`);
    }
    startLoadingAssets() {
        return __awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve) => __awaiter(this, void 0, void 0, function* () {
                const load = this.load;
                //
                console.log("loading tasks before assets...");
                yield this.loadAllTasks(true);
                console.log("loading assets...");
                //
                for (const loadAsset of this._loadAssets) {
                    if (loadAsset.asset.loadState == assetLoad_1.LoadState.LOADED)
                        continue;
                    const key = loadAsset.asset.key;
                    const path = loadAsset.asset.path;
                    loadAsset.asset.loadState = assetLoad_1.LoadState.LOADING;
                    if (loadAsset.asset.type == assetLoad_1.AssetType.IMAGE)
                        load.image(key, path);
                    if (loadAsset.asset.type == assetLoad_1.AssetType.AUDIO) {
                        console.warn("Áudio não é suportado no IOS AINDA");
                        //load.audio(key, path, { noCache: true });
                    }
                    if (loadAsset.asset.type == assetLoad_1.AssetType.ATLAS)
                        load.atlas(key, `${path}.png`, `${path}.json`);
                }
                //
                load.once('complete', () => __awaiter(this, void 0, void 0, function* () {
                    console.log("load completed");
                    for (const loadAsset of this._loadAssets) {
                        if (loadAsset.asset.loadState == assetLoad_1.LoadState.LOADED)
                            continue;
                        console.log(loadAsset);
                    }
                    console.log("loading audios...");
                    yield audioManager_1.AudioManager.loadAllAudios();
                    console.log("loading tasks...");
                    yield this.loadAllTasks(false);
                    resolve();
                }));
                load.start();
            }));
        });
    }
    loadAllTasks(beforeLoad) {
        return __awaiter(this, void 0, void 0, function* () {
            for (const loadAsset of this._loadAssets) {
                if (loadAsset.asset.loadState == assetLoad_1.LoadState.LOADED)
                    continue;
                if (beforeLoad) {
                    if (loadAsset.asset.type != assetLoad_1.AssetType.TASK_BEFORE_LOAD)
                        continue;
                }
                else {
                    if (loadAsset.asset.type != assetLoad_1.AssetType.TASK_AFTER_LOAD)
                        continue;
                }
                this.printLoadState();
                loadAsset.asset.loadState = assetLoad_1.LoadState.LOADING;
                const key = loadAsset.asset.key;
                const fn = loadAsset.asset.fn;
                console.log(`Awaiting task ${key}`);
                if (fn)
                    yield fn();
                console.log(`Task ${key} finished`);
                loadAsset.asset.loadState = assetLoad_1.LoadState.LOADED;
                this.printLoadState();
            }
        });
    }
}
exports.LoadScene = LoadScene;
