"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.eMatchStatus = void 0;
var eMatchStatus;
(function (eMatchStatus) {
    eMatchStatus["NONE"] = "NONE";
    eMatchStatus["STARTED"] = "STARTED";
    eMatchStatus["FINISHED"] = "FINISHED";
    eMatchStatus["ERROR"] = "ERROR";
    eMatchStatus["DISCONNECTED"] = "DISCONNECTED";
})(eMatchStatus || (exports.eMatchStatus = eMatchStatus = {}));
;
