"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Hud = void 0;
const button_1 = require("../../utils/ui/button");
const options_1 = require("../../utils/ui/options");
const mainScene_1 = require("../scenes/mainScene");
class Hud {
    static addButton(text, x, y, width, height, texture) {
        const scene = this.getScene();
        const button = new button_1.Button(scene, text, x, y, width, height, texture);
        this.addToHudLayer(button.container);
        return button;
    }
    static addOptions(displaySize) {
        const scene = this.getScene();
        const options = new options_1.Options(scene, displaySize);
        this.addToHudLayer(options.container);
        return options;
    }
    static getScene() {
        return mainScene_1.MainScene.Instance;
    }
    static addToHudLayer(gameObject) {
        mainScene_1.MainScene.Instance.layerHud.add(gameObject);
    }
}
exports.Hud = Hud;
